import React, { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spacer,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { FaGift, FaPencilAlt, FaTrash } from 'react-icons/fa';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { IModule, IStep, StepType } from '../../types';
import StepEditor from './StepEditor';
import { updateModule } from '../../API/module';
import { createStep, deleteStep } from '../../API/step';
import plus_ico from '../../resources/plus_ico.svg';

export interface ModuleEditorProps {
    module:IModule;
    delete_callback: () => void;
    update_title_callback: (new_title: string) => void;
}

const ModuleEditor: React.FunctionComponent<ModuleEditorProps> = ({module, delete_callback, update_title_callback}) => {
    const [currentModule, setCurrentModule] = useState<IModule>(module);
    const titleRef = useRef<HTMLInputElement>(null);
    const toast = useToast();
    const [plus_visible, setPlusVisible] = useState<number>(-1);
    const plus_open = useDisclosure();
    const delete_module_modal = useDisclosure();

    const update_title = () => {
        const new_title = titleRef.current?.value;
        if (!new_title) {
            return;
        }
        if (new_title === currentModule.title) {
            return;
        }
        const updatedModule = {
            ...currentModule,
            title: new_title || '',
        };
        updateModule(currentModule._id, updatedModule).then(() => {
            setCurrentModule(updatedModule);
            update_title_callback(new_title || '');
            toast({
                title: "Succès",
                description: "Le titre du module a été mis à jour.",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
                size: "sm",
            });
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Impossible de mettre à jour le titre du module.",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        });
    }

    const update_published_at = (new_published_at: Date) => {
        // check if the difference in seconds is less than 1 compared
        //  to the previous date to avoid unnecessary updates
        if (Math.abs(new_published_at.getTime() - (new Date(currentModule.published_at)).getTime()) < 1000) {
            return;
        }
        const updatedModule = {
            ...currentModule,
            published_at: new_published_at,
        };

        updateModule(currentModule._id, updatedModule).then(() => {
            setCurrentModule(updatedModule);
            toast({
                title: "Succès",
                description: "La date de publication a été mise à jour.",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
                size: "sm",
            });
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Impossible de mettre à jour la date de publication.",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        });
    }

    const update_happyhour_end = (new_happyhour_end: Date) => {
        // check if the difference in seconds is less than 1 compared
        //  to the previous date to avoid unnecessary updates
        if (Math.abs(new_happyhour_end.getTime() - new Date(currentModule.happyhour_end).getTime()) < 1000) {
            return;
        }
        const updatedModule = {
            ...currentModule,
            happyhour_end: new_happyhour_end,
        };

        updateModule(currentModule._id, updatedModule).then(() => {
            setCurrentModule(updatedModule);
            toast({
                title: "Succès",
                description: "La date de fin des happy hours a été mise à jour.",
                status: "success",
                duration: 2000,
                isClosable: true,
                position: "top-right",
                size: "sm",
            });
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Impossible de mettre à jour la date de fin des happy hours.",
                status: "error",
                duration: 2000,
                isClosable: true,
                position: "top-right",
            });
        });
    }

    useEffect(() => {
        titleRef.current!.value = currentModule.title;
    }, [module]);
  
    const addStep = (type: StepType, index:number=-1) => {
        toast({
            title: "Mise à jour",
            description: "Construction d'une étape...",
            status: "info",
            duration: 1000,
            isClosable: true,
            position: "top-right",
        });

        createStep(module._id, type, index).then((response) => {
            const new_step:IStep = response.data;
            
            // Updated module version - if the index is not -1, insert it after the index
            let updatedModule:IModule;
            if (index != -1) {
                updatedModule = {
                    ...currentModule,
                    steps: currentModule.steps.slice(0, index + 1).concat([new_step]).concat(currentModule.steps.slice(index + 1)),
                };
            } else {
                updatedModule = {
                    ...currentModule,
                    steps: [...currentModule.steps, new_step],
                };
            }
            setCurrentModule(updatedModule);
            toast({
                title: "Succès",
                description: "Étape ajoutée avec succès.",
                status: "success",
                duration: 1000,
                isClosable: true,
                position: "top-right",
            });
        }).catch((error) => {
            toast({
                title: "Erreur",
                description: "Impossible de créer une étape.",
                status: "error",
                duration: 1000,
                isClosable: true,
                position: "top-right",
            });
        });
    };

    const delete_step = (index: number) => {
        const step_id:string = currentModule.steps[index]._id;
        const updatedModule = {
            ...currentModule,
            steps: currentModule.steps.filter((step, i) => i !== index),
        };
        toast({
            title: "Mise à jour",
            description: "Destruction de l'étape...",
            status: "info",
            duration: 1000,
            isClosable: true,
            position: "top-right",
        });
        setCurrentModule(updatedModule);
        deleteStep(step_id).then(() => {
            toast({
                title: "Succès",
                description: "L'étape a été détruite.",
                status: "success",
                duration: 1000,
                isClosable: true,
                position: "top-right",
            });
        }).catch(() => {
            toast({
                title: "Erreur",
                description: "Impossible de supprimer l'étape.",
                status: "error",
                duration: 1000,
                isClosable: true,
                position: "top-right",
            });
        });
    }

    const updateStepTitle = (index: number, new_title: string) => {
        const updatedModule = {
            ...currentModule,
            steps: currentModule.steps.map((step, i) => {
                if (i === index) {
                    return {
                        ...step,
                        title: new_title,
                    };
                }
                return step;
            }),
        };
        setCurrentModule(updatedModule);
    }

    return (
        <Box mt={2}>
            <Modal isOpen={delete_module_modal.isOpen} onClose={delete_module_modal.onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader fontWeight={800}>Supprimer le module</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text fontWeight={600}>Le module et toutes ses étapes seront définitivement supprimés. Êtes-vous sûr de vouloir continuer ?</Text>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={() => {delete_callback(); delete_module_modal.onClose();}}>
                        Oui
                    </Button>
                    <Button variant={'ghost'} onClick={delete_module_modal.onClose}>Retour</Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
            
            <Text mt={5} mb={5} color={"#4B4B4B"} fontSize={"xl"} fontWeight={800}>Paramètres généraux</Text>

            <FormControl mt={2}>
                <FormLabel color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>TITRE DU MODULE</FormLabel>
                <Input width={'100%'} p={5} rounded={'lg'} fontWeight={600} color={"#777777"} bgColor={"#F7F7F7"} borderWidth={'2px'} borderColor={"#E5E5E5"} me={4} ref={titleRef} placeholder='e.g., Blocs, variables et Instructions' onBlur={update_title} />
            </FormControl>

            <Flex mt={2}>
                <FormControl mt={2}>
                    <FormLabel color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>DATE DE PUBLICATION</FormLabel>
                    <Flex>
                        <Input 
                            p={5} 
                            rounded={'lg'} 
                            fontWeight={600} 
                            color={"#777777"} 
                            bgColor={"#F7F7F7"} 
                            borderWidth={'2px'} 
                            borderColor={"#E5E5E5"} 
                            type='date' 
                            defaultValue={new Date(module.published_at).toISOString().split('T')[0]}
                            onBlur={(event) => {update_published_at(new Date(event.target.value))}}
                            me={2} 
                        />
                    </Flex>
                    <FormHelperText>Le module sera visible aux étudiant·e·s à partir du ...</FormHelperText>
                </FormControl>
                <FormControl mt={2}>
                    <FormLabel color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>FIN DES HAPPY HOURS</FormLabel>
                    <Flex>
                        <Input 
                            p={5} 
                            rounded={'lg'} 
                            fontWeight={600} 
                            color={"#777777"} 
                            bgColor={"#F7F7F7"} 
                            borderWidth={'2px'} 
                            borderColor={"#E5E5E5"} 
                            type='date' 
                            defaultValue={new Date(module.happyhour_end).toISOString().split('T')[0]}
                            onBlur={(event) => {update_happyhour_end(new Date(event.target.value))}}
                            me={2} 
                        />
                    </Flex>
                    <FormHelperText>Les étapes rapporteront deux fois plus de diamants jusqu'au ...</FormHelperText>
                </FormControl>
            </Flex>

            <Center mt={7} mb={6}>
                <Divider width={"90%"} borderWidth={'2px'} borderColor={'#E5E5E5'} />
            </Center>

            <Text color={"#4B4B4B"} fontSize={"xl"} fontWeight={800}>Liste des étapes</Text>

            {currentModule.steps.length == 0 &&
                <Center mt={4} mb={6}>
                    <Text fontSize={"md"} fontWeight={600} color={"gray"}>Aucune étape pour le moment</Text>
                </Center>
            }
            {currentModule.steps.length > 0 && 
                <Accordion p={4} allowToggle>
                    {currentModule.steps.map((step, index) => (
                        <AccordionItem key={index} mb={2} position={"relative"}>
                            <AccordionButton borderWidth={"2px"} borderColor={'#E5E5E5'} p={5}>
                                <Box as='span' flex='1' textAlign='left' pb={0} mb={0}>
                                    <Flex>
                                        {step.type == StepType.REWARD &&
                                            <Image src={"https://d35aaqx5ub95lt.cloudfront.net/images/goals/ca23da57929a3144934ee0571a2f44e9.svg"} height={"25px"} me={2} />
                                        }
                                        {step.type != StepType.REWARD && step.title &&
                                            <Text fontWeight={800} pb={0} mb={0}>Étape {index + 1}{(step.title.length > 0) ? " : "+step.title : ""}</Text>
                                        }
                                        {!step.title &&
                                            <Text fontWeight={800} pb={0} mb={0}>Étape {index + 1}</Text>
                                        }
                                    </Flex>
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>

                            <AccordionPanel p={5} border={"2px solid #e2e8f0"} borderTop={0} bgColor={"#f7f7f7"}>
                                <StepEditor module={currentModule} step={step} delete_callback={() => {delete_step(index)}} update_title_callback={(newTitle:string) => {updateStepTitle(index, newTitle)}} />
                            </AccordionPanel>

                            <Box 
                                opacity={(plus_visible == index) ? "1" : "0"}
                                zIndex={1}
                                onMouseOver={() => setPlusVisible(index)}
                                onMouseOut={() => {setPlusVisible(-1)}}
                                right={"-35px"}
                                bottom={"-15px"}
                                position={"absolute"} 
                                height={"30px"} 
                                width={"30px"}>
                                <Popover>
                                    <PopoverTrigger>
                                        <Image src={plus_ico} height={"30px"} cursor={"pointer"} onClick={plus_open.onToggle} />
                                    </PopoverTrigger>
                                    <PopoverContent onMouseOut={plus_open.onClose}>
                                        <PopoverArrow />
                                        <PopoverBody p={4}>
                                            <Box mb={2}>
                                                <Button width={"100%"} size={"sm"} onClick={() => {addStep(StepType.EXERCISE, index);setPlusVisible(-1);}}><FaPencilAlt style={{marginRight:"5px"}} />Questions</Button>
                                            </Box>
                                            <Box>
                                                <Button width={"100%"} size={"sm"} onClick={() => {addStep(StepType.REWARD, index);setPlusVisible(-1);}}><FaGift style={{marginRight:"5px"}} />Récompense</Button>
                                            </Box>
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            </Box>
                            <Box 
                                opacity={0}
                                zIndex={0}
                                onMouseOver={() => setPlusVisible(index)}
                                onMouseOut={() => setPlusVisible(-1)}
                                right={"-35px"}
                                bottom={"-30px"}
                                position={"absolute"} 
                                height={"60px"} 
                                width={"150px"}
                                bgColor={"rgba(255, 0, 0, 0.5)"}
                                />
                        </AccordionItem>
                    ))}
                </Accordion>
            }

            <Flex mt={5}>
                <Button rightIcon={<FaTrash />} size={"sm"} borderWidth={"2px"} borderColor={'#D73B3B'} onClick={delete_module_modal.onOpen} colorScheme='red'>
                    <Text fontWeight={700}>Supprimer le module</Text>
                </Button>
                <Spacer />
                <Flex>
                    <Menu>
                        <MenuButton size={"sm"} borderWidth={"2px"} as={Button} rightIcon={<ChevronDownIcon />}>
                            <Text fontWeight={700}>Ajouter une étape</Text>
                        </MenuButton>
                        <MenuList>
                            <MenuItem fontWeight={800} fontSize={"sm"} onClick={() => addStep(StepType.EXERCISE)}><FaPencilAlt style={{marginRight:"8px"}} />Questions</MenuItem>
                            <MenuItem fontWeight={800} fontSize={"sm"} onClick={() => addStep(StepType.REWARD)}><FaGift style={{marginRight:"8px"}} />Récompense</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </Flex>
        </Box>
    );
  }
  export default ModuleEditor;