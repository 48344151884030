import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  Skeleton,
  Spacer,
  Square,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MobileNav } from './MobileNav';
import { SidebarContent } from './SidebarContent';
import { faFileExport, faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import flag from '../resources/step/flag.svg';
import bookie from '../resources/step/bookie.svg';
import export_icon from '../resources/admin/export_icon.svg';
import students_icon from '../resources/admin/students_icon.svg';
import { FaChevronDown } from 'react-icons/fa';
import { StudentProfile } from './StudentProfile';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ICourse, IUser } from '../types';
import { getCourseStudents, getMyCourses } from '../API/course';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import { cursorTo } from 'readline';
import { FaCrown } from 'react-icons/fa';

const Students: React.FunctionComponent = () => {
  const navigate = useNavigate();
    const [courses, setCourses] = useState<ICourse[]>([]);
    const [currentCourse, setCurrentCourse] = useState<ICourse|null>(null);
    const [loading, setLoading] = useState(true);
    const [studentsLoading, setStudentsLoading] = useState(true);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const drawerDisclosure = useDisclosure();
    const [students, setStudents] = useState<IUser[]>([]);
    const [selectedStudent, setSelectedStudent] = useState<IUser|null>(null);
    const toast = useToast();
    
    // Middleware
    const { user } = useUser();
    useEffect(() => {
      if (!user || user.rank != 'admin') {
        navigate('/');
      }
    }, []);

    useEffect(() => {
      getMyCourses().then((response) => {
        setCourses(response.data);
        if (response.data.length > 0) {
          setCurrentCourse(response.data[0]);
        } else {
          setLoading(false);
        }
      }).finally(() => {
        setLoading(false);
      });
    }, []);

    useEffect(() => {
      if (currentCourse) {
        getCourseStudents(currentCourse.code).then((response) => {
          setStudents(response.data);
          setFilteredStudents(response.data);
          setStudentsLoading(false);
        });
      }
    }, [currentCourse]);
    
    const select_course = (index:number) => {
      if (courses[index] == currentCourse) {
        return;
      }
      setCurrentCourse(courses[index]);
    }

    // Returns a string with the number formatted with spaces as thousands
    const prettyNumber = (num: number) => {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    const [searchQuery, setSearchQuery] = useState<string>('');
    const [filteredStudents, setFilteredStudents] = useState(students);

    const search_students = (search: string) => {
      setSearchQuery(search);  // Update the search query state

      if (search === '') {
        // If the search is empty, reset to the original list of students
        setFilteredStudents(students);
      } else {
        // Filter students based on the search query (case-insensitive)
        const filtered = students.filter(student => 
          student.firstname.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredStudents(filtered);
      }
    };

    enum FilterType {
      NONE,
      NAME,
      MATRICULE,
      DIAMONDS,
      XP,
      AVERAGE
    }
    const [selectedFilter, setSelectedFilter] = useState<FilterType>(FilterType.NONE);
    const [ascending, setAscending] = useState<boolean>(true);
    
    const change_filter = (filter: FilterType) => {
      if (selectedFilter === filter) {
        // Toggle ascending and use the new value directly in filter_students
        const newAscending = !ascending;
        setAscending(newAscending);
        filter_students(filter, newAscending);  // Use the toggled value
      } else {
        // Set a new filter and reset to ascending
        setSelectedFilter(filter);
        setAscending(true);
        filter_students(filter, true);  // Always start with ascending for new filters
      }
    }

    /**
     * Filter students based on the filter type and the order.
     * If filtered by name, the students will be sorted alphabetically by first name.
     */
    const filter_students = (filter:FilterType, asc:boolean) => {
      let studentsCopy = filteredStudents.slice();
      switch (filter) {
        case FilterType.NAME:
          studentsCopy.sort((a, b) => {
            return asc
              ? a.firstname.localeCompare(b.firstname, 'en', { sensitivity: 'base' })
              : b.firstname.localeCompare(a.firstname, 'en', { sensitivity: 'base' });
          });
          break;
        case FilterType.MATRICULE:
          studentsCopy.sort((a, b) => {
            if (a.matricule < b.matricule) {
              return asc ? -1 : 1;
            } else if (a.matricule > b.matricule) {
              return asc ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case FilterType.DIAMONDS:
          studentsCopy.sort((a, b) => {
            if (a.diamonds < b.diamonds) {
              return asc ? -1 : 1;
            } else if (a.diamonds > b.diamonds) {
              return asc ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case FilterType.XP:
          studentsCopy.sort((a, b) => {
            if (a.xp < b.xp) {
              return asc ? -1 : 1;
            } else if (a.xp > b.xp) {
              return asc ? 1 : -1;
            } else {
              return 0;
            }
          });
          break;
        case FilterType.AVERAGE:
          // Not implemented
          break;
        default:
          break;
      }
      setFilteredStudents(studentsCopy);
    }
  
    return (<>
      {user && user.rank == "admin" && <Box minH="100vh" bg={"white"}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />

        <StudentProfile student={selectedStudent} isOpen={drawerDisclosure.isOpen} onOpen={drawerDisclosure.onOpen} onClose={drawerDisclosure.onClose} />
        
        <Box ml={{ base: 0, md: 60 }} p="10" color={"#4b4b4b"}>
          <Flex>
            {!loading &&
              <Text mb={10} fontSize={"3xl"} fontWeight={800} cursor={'default'}>{currentCourse?.title}</Text>
            }
            {loading &&
              <Skeleton mb={10} height="35px" width="440px" />
            }
            <Spacer />
            <Menu>
                <MenuButton isDisabled={loading} borderColor={"#e5e5e5"} borderWidth={"2px"} variant='outline' as={Button} rightIcon={<ChevronDownIcon />}>
                    <Text fontWeight={600}>Mes cours</Text>
                </MenuButton>
                <MenuList>
                  {courses.map((course, index) => (
                    <MenuItem fontWeight={currentCourse?.code == course.code ? 800 : 600} onClick={() => {select_course(index)}} key={index}>{course.title}</MenuItem>
                  ))}
                </MenuList>
            </Menu>
          </Flex>

          <Flex cursor={'default'}>
            {studentsLoading && 
              <Skeleton width={"45px"} height={"20px"} mt={1} me={1} />
            }
            <Text fontSize={"xl"} fontWeight={800}>
            {!studentsLoading && students.length} Étudiant·e{(students.length > 1 || students.length == 0) ? "·s" : ""}
            </Text>
          </Flex>

          <Input
            color={'#777'}
            fontWeight={600}
            borderColor={"#e5e5e5"}
            borderWidth={"2px"}
            mt={5}
            placeholder="Rechercher un·e étudiant·e"
            value={searchQuery}  // This ensures the input is controlled and displays the current search query
            onChange={(e) => search_students(e.target.value)}  // Call the search function on input change
          />

          <Flex mt={5} me={2}>
            <Spacer />
            <Text
              onClick={() => {
                toast({
                  title: "Fonctionnalité non implémentée",
                  description: "Cette fonctionnalité n'est pas encore disponible.",
                  status: "info",
                  position: "top-right",
                  duration: 5000,
                  isClosable: true,
                });
              }} 
              cursor={"pointer"} fontSize={'sm'} fontWeight={850} color={'#AFAFAF'} ><Image me={1} position={'relative'} top={"1px"} display={'inline'} src={export_icon} />EXPORTER L'ACTIVITÉ</Text>
            <Text 
              onClick={() => {
                toast({
                  title: "Fonctionnalité non implémentée",
                  description: "Cette fonctionnalité n'est pas encore disponible.",
                  status: "info",
                  position: "top-right",
                  duration: 5000,
                  isClosable: true,
                });
              }} 
              cursor={"pointer"} ms={5} fontSize={'sm'} fontWeight={900} color={'#AFAFAF'} ><Image me={1} position={'relative'} top={"1px"} display={'inline'} src={students_icon} />PROGRESSION</Text>
          </Flex>

          <Flex>
            <TableContainer mt={5} width={"100%"}>
              <Table variant='simple' border={"2pt solid #e5e5e5"} style={{borderRadius:"90px"}}>
                <Thead borderBottom={"2pt solid #e5e5e5"}>
                  <Tr cursor={'default'}>
                    <Th onClick={() => {change_filter(FilterType.NAME)}} >
                      <Text 
                        cursor={"pointer"} 
                        fontWeight={(selectedFilter == FilterType.NAME) ? 900 : 700} 
                        color={(selectedFilter == FilterType.NAME) ? '#4B4B4B' : '#AFAFAF'} 
                        fontSize={'sm'}>
                          Nom <FontAwesomeIcon style={{ transform: (selectedFilter == FilterType.NAME && !ascending) ? 'rotate(180deg)' : 'none' }} icon={faFilter} />
                      </Text>
                    </Th>
                    <Th onClick={() => {change_filter(FilterType.MATRICULE)}} >
                      <Text 
                        cursor={"pointer"} 
                        fontWeight={(selectedFilter == FilterType.MATRICULE) ? 900 : 700} 
                        color={(selectedFilter == FilterType.MATRICULE) ? '#4B4B4B' : '#AFAFAF'} 
                        fontSize={'sm'}>
                          Matricule <FontAwesomeIcon style={{ transform: (selectedFilter == FilterType.MATRICULE && !ascending) ? 'rotate(180deg)' : 'none' }} icon={faFilter} />
                      </Text>
                    </Th>
                    <Th isNumeric onClick={() => {change_filter(FilterType.DIAMONDS)}} >
                      <Text 
                        cursor={"pointer"} 
                        fontWeight={(selectedFilter == FilterType.DIAMONDS) ? 900 : 700} 
                        color={(selectedFilter == FilterType.DIAMONDS) ? '#4B4B4B' : '#AFAFAF'} 
                        fontSize={'sm'}>
                          Diamants <FontAwesomeIcon style={{ transform: (selectedFilter == FilterType.DIAMONDS && !ascending) ? 'rotate(180deg)' : 'none' }} icon={faFilter} />
                      </Text>
                    </Th>
                    <Th isNumeric onClick={() => {change_filter(FilterType.XP)}} >
                      <Text 
                        cursor={"pointer"} 
                        fontWeight={(selectedFilter == FilterType.XP) ? 900 : 700} 
                        color={(selectedFilter == FilterType.XP) ? '#4B4B4B' : '#AFAFAF'} 
                        fontSize={'sm'}>
                          XP <FontAwesomeIcon style={{ transform: (selectedFilter == FilterType.XP && !ascending) ? 'rotate(180deg)' : 'none' }} icon={faFilter} />
                      </Text>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                {!studentsLoading && students.length == 0 &&
                  <Tr>
                    <Td colSpan={4} textAlign={'center'} cursor={'default'}>Aucun·e étudiant·e dans ce cours</Td>
                  </Tr>
                }
                  {!studentsLoading && filteredStudents.map((student, index) => (
                    <Tr 
                      cursor={"pointer"} _hover={{backgroundColor:"#ddf4ff"}}
                      onClick={() => {
                        setSelectedStudent(student);
                        drawerDisclosure.onOpen();
                      }}
                      color={"#777"}
                      fontWeight={600}
                      key={index}
                    >
                      <Td maxWidth={'250px'} textOverflow={'ellipsis'} overflow={'hidden'} >
                        {student.rank == "admin" &&
                          <HStack>
                            <FaCrown display={"inline"} />
                            <Text>{(student.firstname + " " + student.lastname).slice(0, 30) + ((student.firstname + " " + student.lastname).length >= 30 ? "..." : "")}</Text>
                          </HStack>
                        }
                        {student.rank != "admin" &&
                            (student.firstname + " " + student.lastname).slice(0, 30) + ((student.firstname + " " + student.lastname).length >= 30 ? "..." : "")
                        }
                      </Td>
                      {/* <Td>{"Angélique Fontaine de la Brassine".slice(0, 20) + ("Angélique Fontaine de la Brassine".length >= 20 ? "..." : "")}</Td> */}
                      <Td textOverflow={'ellipsis'} overflow={'hidden'} >
                        {student.matricule}
                      </Td>
                      <Td isNumeric>
                        {prettyNumber(student.diamonds)}
                      </Td>
                      <Td isNumeric>
                        {prettyNumber(student.xp)}
                      </Td>
                    </Tr>
                  ))
                  }
                  {studentsLoading && [...Array(5)].map((_, index) => (
                    <Tr key={index}>
                      <Td>
                        <Skeleton height="20px" width="100%" />
                      </Td>
                      <Td>
                        <Skeleton height="20px" width="100%" />
                      </Td>
                      <Td isNumeric>
                        <Skeleton height="20px" width="100%" />
                      </Td>
                      <Td isNumeric>
                        <Skeleton height="20px" width="100%" />
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        </Box>
      </Box>
      }
    </>);
  }
  export default Students;