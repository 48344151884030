import {
  Flex,
  Icon,
  Link,
  FlexProps,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { useNavigate } from 'react-router-dom';

interface NavItemProps extends FlexProps {
    icon: IconType;
    path: string;
    secret?: boolean;
    external?: boolean;
    children: any;
}
export const NavItem = ({ icon, path, secret, external, children, ...rest }: NavItemProps) => {
  const navigate = useNavigate();

  return (
    <Link onClick={
      (e) => {
        e.preventDefault();
        if (external) {
          window.location.href = path;
        } else
          navigate(path);
      }
    } style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        color={secret ? "red.300" : "white"}
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'cyan.400',
          color: 'white',
        }}
        {...rest}>
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
  };