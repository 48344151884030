import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  AbsoluteCenter,
  Spacer,
  Tooltip,
  Fade,
  Collapse,
} from '@chakra-ui/react';
import team_img from './resources/team.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import {Image as ChakraImage} from '@chakra-ui/react';
import { getDowntime } from './API/userData';
import { IDowntime } from './types';


const Downtime: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [dt, setDt] = useState<IDowntime | null>(null);

    useEffect(() => {
      // Preload images
      const img = new Image();
      img.src = team_img;
    }, []);
    
    // Downtime
    useEffect(() => {
        getDowntime().then((response) => {
            const downtime:IDowntime = response.data;
            setDt(downtime);
            if (moment().isAfter(downtime.end)) {
            navigate('/');
            }
        });
    }, []);

    return (
      <Box minH="100vh" bg={"#131f24"} color={'white'} cursor={'default'}>
        <Box height={'200px'} />
        <Center>
            <Text fontSize={'3xl'} fontWeight={800}>Maintenance en cours...</Text>
        </Center>
        <Center>
            <ChakraImage draggable={false} src={team_img} alt="team" width={'40%'} />
        </Center>
        <Center mt={2}>
            <Text fontSize={'xl'} color={'#52656D'} fontWeight={600}>Une maintenance de quelques minutes est en cours, le site sera de retour très vite !</Text>
        </Center>
        {dt && 
            <Center mt={2}>
                <Text fontSize={'md'} color={'white'} fontWeight={600}>Raison de la maintenance : {dt.reason}</Text>
            </Center>
        }
        <Box height={'50px'} />
      </Box>
    );
  }
  export default Downtime;