import { Box, Button, Checkbox, Flex, FormControl, FormLabel, Input, Radio, Spacer, Table, TableContainer, Tbody, Td, Text, Textarea, Th, Thead, Tooltip, Tr } from "@chakra-ui/react"
import { faDeleteLeft, faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IMcq, ISection } from "../types";
import { useState, useRef, useEffect } from "react";
import { updateQuestion } from "../API/course";
import TextEditor from "../components/TextEditor";
import AceEditor from 'react-ace';
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/theme-dracula";
import "ace-builds/src-noconflict/ext-language_tools";

export interface PoolQuestionEditorProps {
    question_index: number;
    question: IMcq;
    delete_callback: () => void;
}

export const PoolQuestionEditor: React.FunctionComponent<PoolQuestionEditorProps> = ({question_index, question, delete_callback}) => {

    const [currentQuestion, setCurrentQuestion] = useState<IMcq>(question);
    const [withCode, setWithCode] = useState<boolean>(false);

    const answerRefs = useRef<(HTMLInputElement | null)[]>([]);
    const feedbackRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(() => {
        if (question.code && question.code !== '') {
            setWithCode(true);
        }
    } , []);

    const add_answer = () => {
        let new_question = {...currentQuestion};
        new_question.answers.push({
            content: '',
            correct: false,
            feedback: ''
        });
        setCurrentQuestion(new_question);

        // Add the references
        answerRefs.current.push(null);
        feedbackRefs.current.push(null);

        // Add the answer in the database
        update_question(new_question);
    }

    const delete_answer = (index:number) => {
        let new_question = {...currentQuestion};
        new_question.answers = new_question.answers.filter((_, i) => i !== index);
        // If no answer is correct, set the first one as correct
        if (new_question.answers.filter(answer => answer.correct).length === 0) {
            new_question.answers[0].correct = true;
        }
        setCurrentQuestion(new_question);

        // Update the references
        answerRefs.current = answerRefs.current.filter((_, i) => i !== index);
        feedbackRefs.current = feedbackRefs.current.filter((_, i) => i !== index);

        // Delete the answer in the database
        update_question(new_question);
    }

    const update_statement = (newStatement:string) => {
            let new_question = {...currentQuestion};
            new_question.statement = newStatement;
            setCurrentQuestion(new_question);

            // Update the question in the database
            update_question(new_question);
    }

    const update_answer = (index: number) => {
        if (answerRefs.current[index]) {
            let new_question = {...currentQuestion};
            new_question.answers[index].content = answerRefs.current[index]!.value;
            setCurrentQuestion(new_question);

            // Update the answer in the database
            update_question(new_question);
        }
    }

    const update_feedback = (index: number) => {
        if (feedbackRefs.current[index]) {
            let new_question = {...currentQuestion};
            new_question.answers[index].feedback = feedbackRefs.current[index]!.value;
            setCurrentQuestion(new_question);

            // Update the feedback in the database
            update_question(new_question);
        }
    }

    const update_correct = (index: number) => {
        let new_question = {...currentQuestion};
        new_question.answers[index].correct = !new_question.answers[index].correct;
        // Only one answer can be correct
        new_question.answers.forEach((answer, i) => {
            if (i !== index) {
                answer.correct = false;
            }
        });
        setCurrentQuestion(new_question);

        // Update the correct answer in the database
        update_question(new_question);
    }

    const update_question = (new_question:IMcq) => {
        updateQuestion(new_question);
    }

    return (
        <Box borderWidth={"2px"} borderColor={"#E5E5E5"} mb={5}>
            <Box p={3} borderBottomWidth={'3px'} borderColor={"#E5E5E5"}>
                <Flex>
                    <Text cursor={'default'} color={'#4B4B4B'} fontSize={'md'} fontWeight={800}>QUESTION {question_index+1}</Text>
                    <Spacer />
                    <Tooltip fontWeight={700} p={3} label="Supprimer la question" aria-label="Supprimer la question">
                        <Box pe={1} className="hvr-grow" onClick={delete_callback}>
                            <FontAwesomeIcon icon={faTrashCan} color={'#4B4B4B'} cursor={'pointer'} />
                        </Box>
                    </Tooltip>
                </Flex>
            </Box>

            <FormControl p={4} pb={0}>
                <FormLabel color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>INTITULÉ DE LA QUESTION</FormLabel>
                <TextEditor 
                    content={currentQuestion.statement}
                    update_callback={(content:string) => {update_statement(content);}}
                />
            </FormControl>

            {/* Checkbox "Ajouter un bout de code" */}
            <FormControl p={4} ps={5} pb={0}>
                <Checkbox 
                    isChecked={withCode}
                    size={'sm'} colorScheme="green" onChange={(e) => {
                        setWithCode(e.target.checked);
                        if (!e.target.checked) {
                            let new_question = {...currentQuestion};
                            new_question.code = '';
                            setCurrentQuestion(new_question);
                            update_question(new_question);
                        }
                }}>
                    <Text fontWeight={700}>Ajouter un bout de code</Text>
                </Checkbox>
            </FormControl>
            
            {withCode &&
                <Box width={'100%'} height={'200px'} maxHeight={'200px'} p={4} pt={0} mb={3} pb={5}>
                    <Box borderWidth={'2px'} borderColor={"#E5E5E5"}>
                    <AceEditor
                        mode="c_cpp"
                        theme="dracula"
                        name="code-editor"
                        fontSize={14}
                        showPrintMargin={false}
                        showGutter={true}
                        highlightActiveLine={true}
                        height={'180px'}
                        value={currentQuestion.code}
                        width={'100%'}
                        setOptions={{
                            showLineNumbers: true,
                            tabSize: 2,
                        }}
                        onChange={(code) => {
                            let new_question = {...currentQuestion};
                            new_question.code = code;
                            setCurrentQuestion(new_question);
                            update_question(new_question);
                        }}
                    />
                    </Box>
                </Box>
            }


            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr cursor={'default'}>
                            <Th width={'10%'}>Correct ?</Th>
                            <Th width={'44%'}>Réponse</Th>
                            <Th width={'44%'}>Feedback</Th>
                            <Th width={'2%'}></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentQuestion.answers.map((answer, index) => (
                            <Tr key={`${index}-${new Date().getTime()}`}>
                                <Td textAlign={'center'}>
                                    <Radio 
                                        colorScheme="green" 
                                        isChecked={answer.correct} 
                                        onChange={() => update_correct(index)} // Update correct answer on change
                                    />
                                </Td>
                                <Td>
                                    <Input 
                                        ref={el => answerRefs.current[index] = el}
                                        p={5} 
                                        rounded={'lg'} 
                                        fontWeight={600}
                                        color={"#777777"} 
                                        bgColor={"#F7F7F7"} 
                                        borderWidth={'2px'} 
                                        borderColor={"#E5E5E5"} 
                                        placeholder=''
                                        defaultValue={answer.content}
                                        onBlur={() => update_answer(index)} // Trigger update on blur
                                        maxLength={500}
                                    />
                                </Td>
                                <Td>
                                    <Input 
                                        ref={el => feedbackRefs.current[index] = el}
                                        p={5} 
                                        rounded={'lg'} 
                                        fontWeight={600}
                                        color={"#777777"} 
                                        bgColor={"#F7F7F7"} 
                                        borderWidth={'2px'} 
                                        borderColor={"#E5E5E5"} 
                                        placeholder=''
                                        defaultValue={answer.feedback}
                                        onBlur={() => update_feedback(index)} // Trigger update on blur
                                        maxLength={500}
                                    />
                                </Td>
                                <Td textAlign={'right'}>
                                    {currentQuestion.answers.length > 2 && 
                                        <Tooltip fontWeight={700} p={3} label="Supprimer la réponse" aria-label="Supprimer la réponse">
                                            <Box pe={1} className="hvr-grow" cursor={'pointer'} onClick={() => delete_answer(index)}>
                                                <FontAwesomeIcon color="#990000" icon={faDeleteLeft} />
                                            </Box>
                                        </Tooltip>
                                    }
                                    {currentQuestion.answers.length <= 2 && 
                                        <Box pe={1}>
                                            <FontAwesomeIcon color="grey" icon={faDeleteLeft} />
                                        </Box>
                                    }
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <Flex>
                <Spacer />
                {currentQuestion.answers.length < 5 &&
                    <Button me={4} mb={4} rightIcon={<FontAwesomeIcon icon={faPlus} />} size={'sm'} variant='outline' borderWidth={"2px"} borderColor={"#E5E5E5"} mt={4} onClick={add_answer}>
                        <Text fontWeight={700}>Ajouter une réponse</Text>
                    </Button>
                }
            </Flex>
        </Box>
    );
};