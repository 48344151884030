import {
  Flex,
  Icon,
  Link,
  FlexProps,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { useNavigate } from 'react-router-dom';

interface NavItemProps extends FlexProps {
    icon: IconType;
    path: string;
    children: any;
    external?: boolean;
}
export const NavItem = ({ icon, path, children, external, ...rest }: NavItemProps) => {
  const current_link = window.location.pathname;

  const navigate = useNavigate();
    return (
      <Link onClick={
        (e) => {
          e.preventDefault();
          navigate(path);
        }
      } style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
        <Flex
          align="center"
          p="4"
          mx="4"
          borderRadius="lg"
          role="group"
          cursor="pointer"
          _hover={{
            bg: 'red.100',
            color: 'red.500',
          }}
          color={external ? "#0bc5ea" : (current_link.includes(path)) ? "#4B4B4B" : "#afafaf"} 
          fontWeight={800}
          {...rest}>
          {icon && (
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: '#afafaf',
              }}
              as={icon}
            />
          )}
          {children}
        </Flex>
      </Link>
    );
  };