import React, { useEffect, useState } from 'react';
import { AbsoluteCenter, Box, Button, Card, CardBody, Center, Container, Flex, Heading, Spacer, Spinner, Text } from '@chakra-ui/react';
import Cookies from 'js-cookie';

const Login: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('Bonjour ?');

    useEffect(() => {
        // Set loading to false after 0.5 second
        setTimeout(() => {
            setLoading(false);
        }, 500);

        // If after 6pm, display "Bonsoir ?"
        const date = new Date();
        if (date.getHours() <= 3 || date.getHours() >= 18) {
            setMessage('Bonsoir ?');
        }
    }, []);

    return (<Box minH="100vh" bg={"#131f24"}>
        {loading &&
            <Box bg={"#131f24"} width={'100%'} height={'100%'} position={'absolute'} zIndex={1000}>
                <AbsoluteCenter>
                    <Box width={'200px'} opacity={0.9}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="#87DCFF" stroke="#87DCFF" strokeWidth="14" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="2" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="#87DCFF" stroke="#87DCFF" strokeWidth="14" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="2" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="#87DCFF" stroke="#87DCFF" strokeWidth="14" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="2" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>
                    </Box>
                </AbsoluteCenter>
            </Box>
        }

        {!loading &&
            <AbsoluteCenter cursor={'default'}>
                <Center>
                    <Heading mt={2} size={'2xl'} fontWeight={800} color={'#0bc5ea'}>{message}</Heading>
                </Center>

                <Center ms={4} me={4} mb={5} mt={5} color={'white'}>
                    <Text>Tu dois te connecter à ton compte ULiège pour accéder au Progress Tracker.</Text>
                </Center>

                <Center>
                    <Box
                        onClick={() => {
                            window.location.href = 'https://cafe.uliege.be/login?redirect_tracker';
                        }}
                        cursor={'pointer'}
                        border={'2pt solid #93D333'} 
                        p={3}
                        mt={3}
                        className="hvr-grow"
                        bgColor={'#93D333'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
                            ME CONNECTER
                    </Box>
                </Center>
            </AbsoluteCenter>
      }
    </Box>);
}

export default Login;