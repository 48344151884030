import axios, { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { IUserlog, IUser, IDowntime } from "../types";

/**
 * GET request to the server to get the user data.
 */
export const getUserData = async (): Promise<AxiosResponse<IUser>> => {
	try {
		const udata: AxiosResponse<IUser> = await API.get(
            `${API_URL}/user/data`
        );

        return udata;
	} catch (error) {
		throw error;
	}
};

/**
 * GET request to the server to get the user data.
 */
export const logEvent = async (log:IUserlog): Promise<AxiosResponse> => {
	try {
		const resp: AxiosResponse = await API.post(
            `${API_URL}/logs`,
			log
        );

        return resp;
	} catch (error) {
		throw error;
	}
};

export const getDowntime = async (): Promise<AxiosResponse<IDowntime>> => {
	try {
		const resp: AxiosResponse<IDowntime> = await API.get(
            `${API_URL}/downtime`
        );

        return resp;
	} catch (error) {
		throw error;
	}
};
export const setDowntime = async (downtime:IDowntime): Promise<AxiosResponse> => {
	try {
		const resp: AxiosResponse = await API.post(
			`${API_URL}/downtime`,
			downtime
		);

        return resp;
	} catch (error) {
		throw error;
	}
};