import { logEvent } from "./API/userData";
import { LogAction } from "./types";

// Class "logger" is a singleton class that logs user's actions to the server
export class Logger {
  private static instance: Logger;
  private constructor() {
    // Private constructor to prevent instantiation
  }

  public static getInstance(): Logger {
    if (!Logger.instance) {
      Logger.instance = new Logger();
    }
    return Logger.instance;
  }

  public log(tab_id:string, action:LogAction, content:any): void {
    logEvent({
        tab_id: tab_id,
        action: action,
        content: content,
    });
  }
}
