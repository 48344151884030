import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Divider,
  Drawer,
  DrawerContent,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MobileNav } from './MobileNav';
import { SidebarContent } from './SidebarContent';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { ICourse, IModule, IUser } from '../types';
import ModuleEditor from './courses/ModuleEditor';
import { getCourseTeachers, getMyCourses } from '../API/course';
import { createModule, deleteModule, getModulesByCourse } from '../API/module';
import { MCQPool } from './MCQPool';
import TextEditor from '../components/TextEditor';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';

const Courses: React.FunctionComponent = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const drawerDisclosure = useDisclosure();
    const [teachers, setTeachers] = useState<IUser[]>([]);
    const [teachersLoaded, setTeachersLoaded] = useState<boolean>(false);
    const [courses, setCourses] = useState<ICourse[]>([]);
    const [currentCourse, setCurrentCourse] = useState<ICourse|null>(null);
    const [modules, setModules] = useState<IModule[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const toast = useToast();

    // Middleware
    const { user } = useUser();
    const navigate = useNavigate();
    useEffect(() => {
      if (!user || user.rank != 'admin') {
        navigate('/');
      }
    }, []);

    const update_module_title = (index:number, new_title: string) => {
      const updatedModules = [...modules];
      updatedModules[index].title = new_title;
      setModules(updatedModules);
    }

    const delete_module = (index:number) => {
      const module_id = modules[index]._id;

      deleteModule(module_id).then(() => {
        const updatedModules = [...modules];
        updatedModules.splice(index, 1);
        setModules(updatedModules);
        toast({
          title: "Succès",
          description: "Le module a été supprimé.",
          status: "success",
          duration: 1000,
          isClosable: true,
          position: "top-right",
        });
      }).catch(() => {
        toast({
          title: "Erreur",
          description: "Impossible de supprimer le module.",
          status: "error",
          duration: 1000,
          isClosable: true,
          position: "top-right",
        });
      }).finally(() => {});
    }

    const addModule = () => {
      setLoading(true);
      toast({
        title: "Nouveau module",
        description: "Création du module...",
        status: "info",
        duration: 1000,
        isClosable: true,
        position: "top-right",
      });
      createModule(currentCourse?.code || "").then((response) => {
        const new_module = response.data;
        setModules([...modules, new_module]);
        toast({
          title: "Succès",
          description: "Le module a été ajouté.",
          status: "success",
          duration: 1000,
          isClosable: true,
          position: "top-right",
        });
      }).catch((error) => {
        toast({
          title: "Erreur",
          description: "Impossible d'ajouter le module.",
          status: "error",
          duration: 1000,
          isClosable: true,
          position: "top-right",
        });
      }).finally(() => {
        setLoading(false);
      });
    }

    const select_course = (index:number) => {
      if (courses[index].code == currentCourse?.code)
        return;
      setLoading(true);
      setTeachersLoaded(false);
      setCurrentCourse(courses[index]);
    }

    useEffect(() => {
      if (currentCourse) {
        getModulesByCourse(currentCourse.code).then((response) => {
          setModules(response.data);
        }).finally(() => {
          setLoading(false);
        });
        getCourseTeachers(currentCourse.code).then((response) => {
          let teachers_data = response.data;
          // organize teachers by alphabetical order
          teachers_data.sort((a:IUser, b:IUser) => {
            if (a.lastname < b.lastname) return -1;
            if (a.lastname > b.lastname) return 1;
            return 0;
          });
          setTeachers(teachers_data);
          setTeachersLoaded(true);
        }).finally(() => {
          setLoading(false);
        });
      }
    }, [currentCourse]);

    useEffect(() => {
      getMyCourses().then((response) => {
        setCourses(response.data);
        if (response.data.length > 0) {
          setCurrentCourse(response.data[0]);
        } else {
          setLoading(false);
        }
      }).finally(() => {
        setLoading(false);
      });
    }, []);

    // Returns teachers names in a string separated by commas
    const teachers_names = () => {
      let names = "";
      if (teachers.length == 2) {
        return teachers[0].firstname + " " + teachers[0].lastname + " et " + teachers[1].firstname + " " + teachers[1].lastname;
      }
      teachers.forEach((teacher, index) => {
        names += teacher.firstname + " " + teacher.lastname;
        if (index < teachers.length - 1) {
          // if penultimate teacher, add "and"
          names += ", ";
          if (index == teachers.length - 2 && teachers.length > 1)
            names += "et ";
        }
      });
      return names;
    }

  
    return (
      <Box minH="100vh" bg={"white"}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        
        <Box ml={{ base: 0, md: 60 }} p="10" color={"#4b4b4b"}>
          <Flex>
            {!loading &&
              <Text fontSize={"3xl"} fontWeight={800} cursor={'default'}>{currentCourse?.title}</Text>
            }
            {loading &&
              <Skeleton height="35px" width="440px" />
            }
            <Spacer />
            <Menu>
                <MenuButton isDisabled={loading} borderColor={"#e5e5e5"} borderWidth={"2px"} variant='outline' as={Button} rightIcon={<ChevronDownIcon />}>
                    <Text fontWeight={"normal"}>Mes cours</Text>
                </MenuButton>
                <MenuList>
                  {courses.map((course, index) => (
                    <MenuItem fontWeight={currentCourse?.code == course.code ? 800 : 600} onClick={() => {select_course(index)}} key={index}>{course.title}</MenuItem>
                  ))}
                </MenuList>
            </Menu>
          </Flex>

          {!teachersLoaded &&
            <HStack mb={10}>
              <Skeleton height="20px" width="100px" />
              <Skeleton height="20px" width="100px" />
            </HStack>
          }
          {teachersLoaded && teachers.length > 0 &&
            <Text mb={10} fontWeight={600} cursor={'default'}>Cours de {teachers_names()}</Text>
          }
          {teachersLoaded && teachers.length == 0 &&
            <Text mb={10} fontWeight={600} cursor={'default'}>Ce cours n'est géré par personne.</Text>
          }

          <Tabs colorScheme='red'>
            <TabList borderBottomWidth={'2px'}>
              <Tab fontWeight={700}>Modules</Tab>
              <Tab fontWeight={700}>Liste de QCM</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <Box>
                  {!loading && modules.length == 0 &&
                    <Box m={'50px'}>
                      <Center>
                        <Image draggable={false} width={'90px'} src="https://schools-cdn.duolingo.com/images/7568e088430bd955149ed4d8d3605131.svg" alt="No modules" />
                      </Center>
                      <Center>
                        <Text cursor={'default'} fontSize={"md"} m={5} color={"#4B4B4B"} fontWeight={800}>Il est temps d'ajouter des modules pour ce cours !</Text>
                      </Center>
                    </Box>
                  }

                  {!loading && modules.length > 0 &&
                    <Accordion allowToggle border={'none'} borderColor={'rgba(0, 0, 0, 0)'} borderWidth={'0px'}>
                      {modules.map((module, index) => (
                        <AccordionItem key={module._id} border={"2px solid #E5E5E5"} rounded={'xl'} mb={3}>
                            <AccordionButton p={5}>
                              <Box as='span' flex='1' textAlign='left' pb={0} mb={0}>
                                <Text color={'#4B4B4B'} fontWeight={800} pb={0} mb={0}>Module {index}{module.title.length > 0 ? " : "+module.title : ""}</Text>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          
                          <AccordionPanel p={5} pt={0}>
                            <ModuleEditor module={module} update_title_callback={(newTitle:string) => {update_module_title(index, newTitle)}} delete_callback={() => {delete_module(index)}} />
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  }

                  {loading &&
                    <VStack>
                      <Skeleton height="80px" width="100%" />
                      <Skeleton height="80px" width="100%" />
                      <Skeleton height="80px" width="100%" />
                    </VStack>
                  }

                  <Divider mt={5} />

                  <Flex mt={5}>
                    <Spacer />
                    <Button isDisabled={loading} variant='outline' borderColor={"#E5E5E5"} borderWidth={"2px"} onClick={addModule}>
                      <Text fontWeight={700}>Créer un module</Text>
                    </Button>
                  </Flex>

                </Box>
              </TabPanel>
              
              {/* QCMs panel */}
              <TabPanel>
                {currentCourse &&
                  <MCQPool course={currentCourse} />
                }
              </TabPanel>
            </TabPanels>
          </Tabs>

        </Box>

        <Box height={'500px'}/>
      </Box>
    );
  }
  export default Courses;