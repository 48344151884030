import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';

export const TabContext = createContext({ currentTab: "" });

export const TabProvider = ({ children }: { children: ReactNode }) => {
  const currentTab = generateTabId();

  function generateTabId() {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  }

  return (
    <TabContext.Provider value={{ currentTab }}>
      {children}
    </TabContext.Provider>
  );
}