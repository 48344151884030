import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import { IChapter, IMcq, IModule, ISection, IStep, StepType } from '../../types';
import { getQuestionsFromStep, updateStep } from '../../API/step';
import report_svg from '../../resources/report.svg';
import { faEraser, faFolderClosed, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getChaptersByCourse, getQuestionsBySection, getSectionsByChapter } from '../../API/course';

export interface StepEditorProps {
    module:IModule;
    step:IStep;
    delete_callback: () => void;

    // Callback to update the visual, not the DB
    update_title_callback: (new_title: string) => void;
}

const StepEditor: React.FunctionComponent<StepEditorProps> = ({module, step, delete_callback, update_title_callback}) => {
    const titleRef = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const [questionsNb, setQuestionsNb] = useState<number>(0);
    const [reward, setReward] = useState<number>(0);
    const [currentStep, setCurrentStep] = useState<IStep>(step);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [questions, setQuestions] = useState<IMcq[]>([]);
    const [chapters, setChapters] = useState<IChapter[]>([]);
    const [selectedChapter, setSelectedChapter] = useState<IChapter | null>(null);
    const [sections, setSections] = useState<ISection[]>([]);
    const [questionsBySection, setQuestionsBySection] = useState<IMcq[]>([]);
    const delete_step_modal = useDisclosure();

    const update_title = () => {
        const new_title = titleRef.current?.value;
        const updatedStep = {
            ...currentStep,
            title: new_title || '',
        };
        setCurrentStep(updatedStep);
        update_title_callback(new_title || '');

        updateStep(currentStep._id, updatedStep).then(() => {}).catch(()=>{});
    }

    const update_description = () => {
        const new_description = descriptionRef.current?.value;
        const updatedStep = {
            ...currentStep,
            description: new_description || '',
        };
        setCurrentStep(updatedStep);

        updateStep(currentStep._id, updatedStep).then(() => {}).catch(()=>{});
    }

    const update_reward = () => {
        const updatedStep = {
            ...currentStep,
            reward: reward,
        };
        setCurrentStep(updatedStep);

        updateStep(currentStep._id, updatedStep).then(() => {}).catch(()=>{});
    }

    const update_qnb = () => {
        const updatedStep:IStep = {
            ...currentStep,
            questions_nb: questionsNb,
        };
        setCurrentStep(updatedStep);

        updateStep(currentStep._id, updatedStep).then(() => {}).catch(()=>{});
    }

    useEffect(() => {
        if (currentStep.type == StepType.EXERCISE) {
            titleRef.current!.value = currentStep.title || '';
            descriptionRef.current!.value = currentStep.description || '';
            setQuestionsNb(currentStep.questions_nb || 0);
        }
        setReward(currentStep.reward || 0);

        // Get the questions from the pool
        getQuestionsFromStep(currentStep._id).then((resp) => {
            setQuestions(resp.data);
        }).catch(() => {});

        // Get the chapters
        getChaptersByCourse(module.course).then((resp) => {
            setChapters(resp.data);
        } ).catch(() => {});
    }, []);

    const select_chapter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value == "") {
            setSelectedChapter(null);
            return;
        }
        const selected = chapters.find((c) => c._id == e.target.value);
        setSelectedChapter(selected || null);

        // Fetch the sections
        getSectionsByChapter(e.target.value).then((resp) => {
            setSections(resp.data);
        } ).catch(() => {});
    }

    const select_section = (e: React.ChangeEvent<HTMLSelectElement>) => {
        if (e.target.value == "") {
            setQuestionsBySection([]);
            return;
        }
        // Fetch the questions
        getQuestionsBySection(e.target.value).then((resp) => {
            setQuestionsBySection(resp.data);
        } ).catch(() => {});
    }

    const isQuestionSelected = (question: IMcq) => {
        return questions.find((q) => q._id == question._id) !== undefined;
    }

    const removeQuestion = (question: IMcq) => {
        // Update the DB
        let newStep = {...currentStep};
        newStep.pool = newStep.pool!.filter((q) => q != question._id);

        updateStep(currentStep._id, newStep).then((resp) => {
            setCurrentStep(resp.data);
            setQuestions(questions.filter((q) => q._id != question._id));
        }).catch(()=>{});
    }

    const addQuestion = (question: IMcq) => {
        // Update the DB
        let newStep = {...currentStep};
        if (!newStep.pool)
            newStep.pool = [];
        newStep.pool = [...newStep.pool, question._id];

        updateStep(currentStep._id, newStep).then((resp) => {
            setQuestions([...questions, question]);
            setCurrentStep(resp.data);
        }).catch(()=>{});
    }

    const updateCheckbox = (e: React.ChangeEvent<HTMLInputElement>, questionId:string) => {
        // If checked, add the question
        const question = questionsBySection.find((q) => q._id == questionId);
        if (question && e.target.checked) {
            addQuestion(question);
        } else if (question) {
            removeQuestion(question);
        }
    }
    
    return (
      <Box>
        <Modal isOpen={delete_step_modal.isOpen} onClose={delete_step_modal.onClose}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader fontWeight={800}>Supprimer l'étape</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text fontWeight={600}>La suppression de l'étape est irréversible. Êtes-vous sûr de vouloir continuer ?</Text>
                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='red' mr={3} onClick={() => {delete_callback(); delete_step_modal.onClose();}}>
                        Oui
                    </Button>
                    <Button variant={'ghost'} onClick={delete_step_modal.onClose}>Retour</Button>
                </ModalFooter>
                </ModalContent>
        </Modal>
        
        <Flex>
            <Text color={"#afafaf"} fontSize={"lg"} fontWeight={900}>{step.type == StepType.EXERCISE ? "QUESTIONS" : "RÉCOMPENSE"}</Text>
            <Spacer />

            <Tooltip label="Supprimer l'étape" aria-label="Supprimer l'étape">
                <Box mt={1} cursor={"pointer"} className='hvr-grow' onClick={delete_step_modal.onOpen}>
                    <FaTrash />
                </Box>
            </Tooltip>
        </Flex>
        
        <Divider borderWidth={"2px"} borderColor={"#b9bcc0"} mt={5} mb={5} />

        {step.type == StepType.EXERCISE &&<Box mb={5}>

            <FormControl>
                <FormLabel color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>TITRE</FormLabel>
                <Input fontWeight={600} borderColor={"#E5E5E5"} borderWidth={'2px'} bg={"white"} placeholder="e.g., Les variables" ref={titleRef} onBlur={update_title} />
            </FormControl>

            <FormControl mt={5}>
                <FormLabel color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>DESCRIPTION</FormLabel>
                <Textarea fontWeight={600} borderColor={"#E5E5E5"} borderWidth={'2px'} bg={"white"} placeholder="Description de l'étape" ref={descriptionRef} onBlur={update_description} />
            </FormControl>
        </Box>}

        <HStack>
            <FormControl me={2}>
                <FormLabel>
                    <HStack>
                        <Image me={1} height={"25px"} src="https://d35aaqx5ub95lt.cloudfront.net/images/gems/45c14e05be9c1af1d7d0b54c6eed7eee.svg" />
                        <Text mt={1} color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>DIAMANTS</Text>
                    </HStack>
                </FormLabel>
                <NumberInput bg={"white"} max={10000} min={0} value={reward}>
                <NumberInputField 
                    fontWeight={600} borderWidth={'2px'} borderColor={"#E5E5E5"} 
                    onBlur={update_reward} 
                    onChange={(e) => setReward(parseInt(e.target.value))}
                />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
                </NumberInput>
            </FormControl>

            {step.type == StepType.EXERCISE &&
            <FormControl me={2}>
                <FormLabel mb={0}>
                    <HStack>
                        <Image me={1} width={"35px"} src={report_svg} />
                        <Text mt={1} color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>NB. DE QUESTIONS À PIOCHER</Text>
                    </HStack>
                </FormLabel>
                <NumberInput bg={"white"} max={10000} min={0} value={questionsNb}>
                <NumberInputField 
                    fontWeight={600} borderWidth={'2px'} borderColor={"#E5E5E5"} 
                    onBlur={update_qnb} 
                    onChange={(e) => setQuestionsNb(parseInt(e.target.value))} 
                />
                <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                </NumberInputStepper>
                </NumberInput>
            </FormControl>
            }
        </HStack>
        
        {step.type == StepType.EXERCISE && <>
        <Text mt={4} color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>LISTE DE QUESTIONS</Text>

        <TableContainer mt={2} bgColor={'white'} p={3} borderWidth={'2px'} borderColor={"#E5E5E5"}>
            <Table variant='simple' size={'sm'}>
                <Thead>
                <Tr cursor={'default'}>
                    <Th width={'98%'} fontWeight={800}>Question</Th>
                    <Th width={'2%'}></Th>
                </Tr>
                </Thead>
                <Tbody>
                    {questions.map((q, i) => (
                        <Tr key={i}>
                            <Td fontWeight={600} maxWidth={'500px'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                                <Text dangerouslySetInnerHTML={{__html:q.statement}} />
                            </Td>
                            <Td textAlign={'center'}>
                                <FontAwesomeIcon 
                                    onClick={() => removeQuestion(q)}
                                    icon={faEraser} cursor={'pointer'} className='hvr-grow' 
                                />
                            </Td>
                        </Tr>
                    ))}
                    {questions.length == 0 && <Tr>
                        <Td textAlign={'center'} colSpan={2}>
                            <Text color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>Aucune question</Text>
                        </Td>
                    </Tr>}
                </Tbody>
            </Table>
        </TableContainer>

        <Divider borderWidth={"2px"} borderColor={"#b9bcc0"} mt={5} mb={5} />

        <Flex>
            <Spacer />
            <Button
                bgColor={'white'}
                mt={0} size={'sm'} variant='outline' borderWidth={"2px"} borderColor={"#E5E5E5"}
                onClick={() => setIsOpen(!isOpen)}
                rightIcon={<FontAwesomeIcon icon={isOpen ? faFolderOpen : faFolderClosed} />}
            >
                <Text fontWeight={700}>Rechercher une question</Text>
            </Button>
        </Flex>
        </>}

        <Collapse in={isOpen} animateOpacity>
            <Box>
                <Text color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>RECHERCHE DE QUESTIONS</Text>
                
                <Select 
                    onChange={select_chapter} 
                    mt={2} placeholder="Choisir un chapitre" bgColor={'white'} fontWeight={600} borderWidth={'2px'} borderColor={"#E5E5E5"} >
                    {chapters.map((c, i) => (
                        <option key={i} value={c._id}>{c.title}</option>
                    ))}
                </Select>

                {selectedChapter && <>
                    <Select
                        onChange={select_section}
                        mt={2} placeholder="Choisir une section" bgColor={'white'} fontWeight={600} borderWidth={'2px'} borderColor={"#E5E5E5"} >
                        {sections.map((s, i) => (
                            <option key={i} value={s._id}>{s.title}</option>
                        ))}
                    </Select>
                    
                    {questionsBySection.length > 0 && <>
                        <TableContainer mt={2} bgColor={'white'} p={3} borderWidth={'2px'} borderColor={"#E5E5E5"}>
                            <Table variant='simple' size={'sm'}>
                                <Thead>
                                <Tr cursor={'default'}>
                                    <Th width={'98%'} fontWeight={800}>Questions associées à la section</Th>
                                    <Th width={'2%'}></Th>
                                </Tr>
                                </Thead>
                                <Tbody>
                                    {questionsBySection.map((q, i) => (
                                        <Tr key={i}>
                                            <Td fontWeight={600} maxWidth={'500px'} overflow={'hidden'} textOverflow={'ellipsis'} whiteSpace={'nowrap'}>
                                                <Text dangerouslySetInnerHTML={{__html:q.statement}} />
                                            </Td>
                                            <Td textAlign={'center'}>
                                                <Checkbox
                                                    onChange={(e) => updateCheckbox(e, q._id)}
                                                    defaultChecked={isQuestionSelected(q)}
                                                    colorScheme="blue"
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </>}
                </>}

            </Box>
        </Collapse>

      </Box>
    );
  }
  export default StepEditor;