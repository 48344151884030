import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Flex, FormControl, FormHelperText, FormLabel, HStack, Input, Radio, Select, Spacer, Table, TableCaption, TableContainer, Tbody, Td, Text, Textarea, Tfoot, Th, Thead, Tooltip, Tr } from "@chakra-ui/react"
import { faDeleteLeft, faInfoCircle, faSquarePlus, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IChapter, ICourse, ISection } from "../types";
import { useEffect, useState } from "react";
import { createSection, getChaptersByCourse, getSectionsByChapter } from "../API/course";
import { PoolSection } from "./PoolSection";

export const MCQPool: React.FunctionComponent<{course:ICourse}> = ({course}) => {

    const [currentCourse, setCurrentCourse] = useState<ICourse|null>(null);
    const [chapters, setChapters] = useState<IChapter[]>([]);
    const [selectedChapter, setSelectedChapter] = useState<IChapter|null>(null);
    const [sections, setSections] = useState<ISection[]>([]);

    useEffect(() => {
        getChaptersByCourse(course.code).then((res) => {
            setChapters(res.data);
        });
    }, [course]);

    const select_chapter = (chapter_id: string) => {
        if (chapter_id === '') {
            setSelectedChapter(null);
            setSections([]);
            return;
        }
        setSelectedChapter(chapters.find(chapter => chapter._id === chapter_id) || null);
        getSectionsByChapter(chapter_id).then((res) => {
            setSections(res.data);
        });
    }

    const new_section = () => {
        if (!selectedChapter) {
            return;
        }

        createSection(selectedChapter!._id).then((res) => {
            setSections([...sections, res.data]);
        });
    }

    return (
        <>
        {/* <Text fontSize={'sm'} fontWeight={600}><FontAwesomeIcon icon={faInfoCircle} /> Chaque cours dispose d'un ensemble de questions à choix multiple qui peuvent être assignés à un ou plusieurs modules.</Text> */}

        <HStack>
            <Select isDisabled={chapters.length === 0}
            onChange={
                (e) => select_chapter(e.target.value)
            } placeholder='Sélectionner un chapitre' mt={4} borderWidth={'2px'} borderColor={'#E5E5E5'} fontWeight={800} size={'lg'}>
                {chapters.map((chapter, index) => (
                    <option key={chapter._id} value={chapter._id}>Chapitre {index+1} - {chapter.title}</option>
                ))}
            </Select>
            <Button variant='outline' borderWidth={"2px"} borderColor={"#E5E5E5"} mt={4} size={'lg'}>
                <Text fontWeight={700}>Nouveau chapitre</Text>
            </Button>
        </HStack>

        <Accordion allowToggle border={'none'} borderColor={'rgba(0, 0, 0, 0)'} borderWidth={'0px'} mt={4}>
            {sections.map((section, index) => (
                <PoolSection section_index={index} section={section} key={section._id} />
            ))}
        </Accordion>

        {selectedChapter &&
            <Flex>
                <Spacer />
                <Button variant='outline' rightIcon={<FontAwesomeIcon icon={faSquarePlus} />} borderWidth={"2px"} borderColor={"#E5E5E5"} mt={4} onClick={new_section}>
                    <Text fontWeight={700}>Nouvelle section</Text>
                </Button>
            </Flex>
        }
        </>
    )
}