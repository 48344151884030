import {
  Box,
  CloseButton,
  Flex,
  Text,
  BoxProps,
} from '@chakra-ui/react';
import { LinkItems } from './LinkItems';
import { NavItem } from './NavItem';
import { useUser } from '../UserContext';

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

export const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    const { user } = useUser();

    return (
      <Box
        transition="3s ease"
        bg={"#131f24"}
        color={"white"}
        borderRight="2px"
        borderRightColor={"#37464f"}
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full"
        {...rest}>
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Text cursor={'default'} fontSize="15pt" fontWeight={900} color={"#0bc5ea"}>
            Progress Tracker
          </Text>
          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        </Flex>
        {user && user.rank == "admin" && LinkItems.map((link) => (
          <NavItem secret={link.secret} external={link.external} key={link.path} path={link.path} fontWeight={800} fontSize={'sm'} icon={link.icon}>
            {link.name.toUpperCase()}
          </NavItem>
        ))}
        {user && user.rank != "admin" && LinkItems.filter(link => !link.secret).map((link) => (
          <NavItem secret={link.secret} external={link.external} key={link.path} path={link.path} fontWeight={800} fontSize={'sm'} icon={link.icon}>
            {link.name.toUpperCase()}
          </NavItem>
        ))}
      </Box>
    );
};