import React, { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  Flex,
  Grid,
  HStack,
  Image,
  Select,
  Skeleton,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { MobileNav } from './MobileNav';
import { SidebarContent } from './SidebarContent';
import pen from '../resources/pen.svg';
import lightning from '../resources/lightning.svg';
import check_img from '../resources/check.svg';
import late_img from '../resources/late.svg';
import stats_img from '../resources/stats.svg';
import timespent_img from '../resources/timespent.svg';
import { Bar, BarChart, CartesianGrid, Cell, Legend, ReferenceLine, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Tooltip as RechartsTooltip } from 'recharts';
import ApexCharts from 'apexcharts';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import { IChallenge } from '../types';
import { getChallenges, getChallengesDistribution, getChallengeStats } from '../API/challenge';


const Challenges: React.FunctionComponent = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    // CHALLENGES MARKS
    const [challengesMarks, setChallengesMarks] = useState<any[]>([]);

    // CURRENT CHALLENGE
    const [averageMark, setAverageMark] = useState<number>(0);
    const [participationRate, setParticipationRate] = useState<number>(0);
    const [totalSubmissions, setTotalSubmissions] = useState<number>(0);
    const [studentsWhoDidNotSubmit, setStudentsWhoDidNotSubmit] = useState<number>(0);
    const [numberOfJokerUsers, setNumberOfJokerUsers] = useState<number>(0);
    const [markDistribution, setMarkDistribution] = useState<any[]>([]);
    const [heatmapData, setHeatmapData] = useState<{name:string,students:number,day:number}[]>([]);
    const [selectedChallenge, setSelectedChallenge] = useState<IChallenge|null>();
    const [challenges, setChallenges] = useState<IChallenge[]>([]);

    useEffect(() => {
      getChallenges().then((response) => {
        let challenges:IChallenge[] = response.data;
        // remove the challenges that are not yet started
        challenges = challenges.filter((challenge) => challenge.startline*1000 < new Date().getTime());
        setChallenges(challenges);
        getChallengesDistribution().then((response) => {
          let cd: any = response.data; // format : {c0:number[], c1:number[], c2:number[], c3:number[], c4:number[], c5:number[]}
          // Create the challengesMarks array
          let cm = [];

          for (let i = 0; i < 20; i++) {
            let challengeMark: any = { name: `${i}-${i + 1}` };

            if (cd.c0 && cd.c0[i] !== undefined) challengeMark.c0 = cd.c0[i];
            if (cd.c1 && cd.c1[i] !== undefined) challengeMark.c1 = cd.c1[i];
            if (cd.c2 && cd.c2[i] !== undefined) challengeMark.c2 = cd.c2[i];
            if (cd.c3 && cd.c3[i] !== undefined) challengeMark.c3 = cd.c3[i];
            if (cd.c4 && cd.c4[i] !== undefined) challengeMark.c4 = cd.c4[i];
            if (cd.c5 && cd.c5[i] !== undefined) challengeMark.c5 = cd.c5[i];

            cm.push(challengeMark);
          }
          setChallengesMarks(cm);
        });
      });
    }, []);

    // Middleware
    const { user } = useUser();
    const navigate = useNavigate();
    useEffect(() => {
      if (!user || user.rank != 'admin') {
        navigate('/');
      }
    }, []);

    // Custom tooltip component
    const HourTooltip = ({ active, payload }: any) => {
      if (active && payload && payload.length) {
        const { day, students } = payload[0].payload;
        return (
          <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
            <p>{`Jour: ${day}`}</p>
            <p>{`Soumissions: ${students}`}</p>
          </div>
        );
      }

      return null;
    };
    const DistroTooltip = ({ active, payload }: any) => {
      if (active && payload && payload.length) {
        const { name, students } = payload[0].payload;
        return (
          <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
            <p>{`${name}/20`}</p>
            <p>{`Étudiant·e·s: ${students}`}</p>
          </div>
        );
      }

      return null;
    };
    
    const ChallengesTooltip = ({ active, payload }: any) => {
      if (active && payload && payload.length) {
        const { name, c0, c1, c2, c3, c4, c5 } = payload[0].payload;
    
        // Collect only the defined challenge data
        const challengeData = [
          c0 !== undefined && `Challenge 0: ${c0}`,
          c1 !== undefined && `Challenge 1: ${c1}`,
          c2 !== undefined && `Challenge 2: ${c2}`,
          c3 !== undefined && `Challenge 3: ${c3}`,
          c4 !== undefined && `Challenge 4: ${c4}`,
          c5 !== undefined && `Challenge 5: ${c5}`,
        ].filter(Boolean); // Filter out any `false` values for undefined challenges
    
        return (
          <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
            <p>{`${name}/20`}</p>
            {challengeData.map((challenge, index) => (
              <p key={index}>{challenge}</p>
            ))}
          </div>
        );
      }
    
      return null;
    };

    const select_challenge = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const challengeId = event.target.value;
      let challenge:IChallenge|undefined = challenges.find((challenge) => challenge._id === challengeId);
      if (!challenge) {
        setSelectedChallenge(null);
        return;
      }
      setSelectedChallenge(challenge);

      // Fetch challenge stats
      getChallengeStats(challengeId).then((response) => {
        const stats:any = response.data;
        setAverageMark(stats.averageMark);
        setParticipationRate(stats.participationRate);
        setTotalSubmissions(stats.totalSubmissions);
        setStudentsWhoDidNotSubmit(stats.studentsWhoDidNotSubmit);
        setNumberOfJokerUsers(stats.numberOfJokerUsers);
        setHeatmapData(stats.heatmapData);
        let distro = stats.markDistribution;

        // Process the mark distribution
        // const challengeDistro = [
        //   {name: '0-1', students: 5},
        // });
        let challengeDistro:{name:string,students:number}[] = [];
        for (let i = 0; i < 20; i++) {
          challengeDistro.push({
            name: `${i}-${i+1}`,
            students: distro[i]
          });
        }
        setMarkDistribution(challengeDistro);
      });
    }
  
    return (
      <Box minH="100vh" bg={"white"}>
        <SidebarContent
          onClose={() => onClose}
          display={{ base: 'none', md: 'block' }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="full">
          <DrawerContent>
            <SidebarContent onClose={onClose} />
          </DrawerContent>
        </Drawer>
        <MobileNav onOpen={onOpen} />
        
        <Box ml={{ base: 0, md: 60 }} p="10" color={"#4b4b4b"}>
          <Text mb={5} fontSize={"3xl"} fontWeight={800} cursor={'default'}>Statistiques des Challenges</Text>
          
          <Text cursor={'default'} mb={5} color={"#717171"} fontSize={"2xl"} fontWeight={"bold"}>Distribution des points par Challenge</Text>

          <Box width={"100%"} height={"300px"}>
            {challengesMarks.length > 0 && 
              <ResponsiveContainer>
                <BarChart
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }} 
                  data={challengesMarks}
                >
                  <XAxis dataKey="name" stroke="#718355" fontWeight={700} />
                  <YAxis />
                  <RechartsTooltip content={<ChallengesTooltip />} />
                  <CartesianGrid stroke="#ccc" strokeDasharray="10 10" />

                  {/* Dynamically render bars for defined challenge data */}
                  {challengesMarks[0].c0 !== undefined && <Bar dataKey="c0" fill="#e9f5db" barSize={30} />}
                  {challengesMarks[0].c1 !== undefined && <Bar dataKey="c1" fill="#cfe1b9" barSize={30} />}
                  {challengesMarks[0].c2 !== undefined && <Bar dataKey="c2" fill="#b5c99a" barSize={30} />}
                  {challengesMarks[0].c3 !== undefined && <Bar dataKey="c3" fill="#97a97c" barSize={30} />}
                  {challengesMarks[0].c4 !== undefined && <Bar dataKey="c4" fill="#87986a" barSize={30} />}
                  {challengesMarks[0].c5 !== undefined && <Bar dataKey="c5" fill="#718355" barSize={30} />}
                </BarChart>
              </ResponsiveContainer>
            }
          </Box>
          
          <Text cursor={'default'} mt={10} mb={5} color={"#717171"} fontSize={"2xl"} fontWeight={"bold"}>Statistiques individuelles</Text>

          <Select size={"lg"} border={"2pt solid #e5e5e5"} placeholder="Choisir un challenge" onChange={select_challenge}>
            {challenges.map((challenge) => (
              <option key={challenge._id} value={challenge._id}>{challenge.title}</option>
            ))}
          </Select>
          {selectedChallenge && 
          <Box>
            <Grid border={"2pt solid #e5e5e5"} templateColumns="repeat(2, 1fr)" gap={6} mt={5}>
              <Box p={5} borderRight={"2pt solid #e5e5e5"}>
                <Flex>
                  <Image me={4} src={pen} width={"35px"} />
                  <Box>
                    <Text cursor={'default'} fontSize={"xl"} fontWeight={"bold"}>Moyenne générale</Text>
                    <Text cursor={'default'} fontSize={"md"} fontWeight={"normal"}>{averageMark}/20</Text>
                  </Box>
                </Flex>
              </Box>
              <Box p={5}>
                <Flex>
                  <Image me={4} src={lightning} height={"35px"} mt={2} />
                  <Box>
                    <Text cursor={'default'} fontSize={"xl"} fontWeight={"bold"}>Participation</Text>
                    <Text cursor={'default'} fontSize={"md"} fontWeight={"normal"}>{participationRate}%</Text>
                  </Box>
                </Flex>
              </Box>
            </Grid>

            <Box border={"2pt solid #e5e5e5"} borderTop={0} p={5}>
              <Flex mt={2} justifyContent={"space-between"}>
                <HStack>
                  <Image src={check_img} height={"15px"} />
                  <Tooltip label="Ont soumis le challenge">
                    <Text cursor={'default'} fontSize={"sm"}>{totalSubmissions} soumissions</Text>
                  </Tooltip>
                </HStack>
                <HStack>
                  <Image src={late_img} height={"15px"} />
                  <Tooltip label="N'ont pas soumis le challenge">
                    <Text cursor={'default'} fontSize={"sm"}>{studentsWhoDidNotSubmit} oublis</Text>
                  </Tooltip>
                </HStack>
                <HStack>
                  <i className="em-svg em-black_joker"></i>
                  <Tooltip label="Jokers utilisés">
                    <Text cursor={'default'} fontSize={"sm"}>{numberOfJokerUsers} jokers</Text>
                  </Tooltip>
                </HStack>
              </Flex>
            </Box>

            <Text cursor={'default'} mt={5} mb={5} color={"#afafaf"} fontSize={"xl"} fontWeight={"bold"}>Distribution des notes</Text>

            <Box width={"100%"} height={"250px"}>
              <ResponsiveContainer>
                <BarChart
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }} 
                  data={markDistribution}>
                  <XAxis dataKey="name" stroke="#cc7800" />
                  <YAxis />
                  <RechartsTooltip content={<DistroTooltip />} />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <Bar dataKey="students" fill="#ff9600" barSize={30} />
                </BarChart>
              </ResponsiveContainer>
            </Box>

            <Text cursor={'default'} mb={5} mt={5} color={"#afafaf"} fontSize={"xl"} fontWeight={"bold"}>Heure de soumission</Text>
            
            <Box width={"100%"} height={"250px"}>
              <ResponsiveContainer>
                <BarChart
                  margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                  }}
                  data={heatmapData}
                >
                  <XAxis dataKey="name" stroke="#22543D" fontWeight={700} />
                  <YAxis />
                  <RechartsTooltip content={<HourTooltip />} />
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  
                  <Bar stackId="a" dataKey="students" >
                    {heatmapData.map((entry:any, index:number) => (
                        <Cell key={index} fill={entry.day === 1 ? '#22543D' : entry.day == 2 ? '#38A169' : "#9AE6B4"} />
                    ))}
                  </Bar>

                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Box>
          }
          <Box height={'400px'}></Box>
        </Box>
      </Box>
    );
  }
  export default Challenges;