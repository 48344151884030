import React, { useEffect } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  Box,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Spacer,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IChallengeSummary, IUser } from '../types';
import moment from 'moment';
import { getChallengesSummaries } from '../API/challenge';

export const StudentProfile: React.FunctionComponent<{
    isOpen: any;
    onOpen: any;
    onClose: any;
    student: IUser|null;
}> = ({isOpen, onOpen, onClose, student}) => {

    const [challengesSummary, setChallengesSummary] = React.useState<IChallengeSummary[]>([]);
    const [summaryFetched, setSummaryFetched] = React.useState<boolean>(false);

    useEffect(() => {
      if (!student) return;
      getChallengesSummaries(student.matricule).then((response) => {
        setChallengesSummary(response.data);
        setSummaryFetched(true);
      } );
    }, [student]);

    return (
      <>
        <Drawer
          size={"lg"}
          isOpen={isOpen}
          placement='right'
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent 
            borderLeft="2px"
            borderLeftColor={"#e5e5e5"}>
            <DrawerCloseButton />
            <DrawerHeader></DrawerHeader>
  
            <DrawerBody>
              <Flex cursor={'default'} pe={3}>
                <Avatar size='xl' name={student?.firstname + ' ' + student?.lastname} src='https://bit.ly/broken-link' />
                <VStack ml={4} align='start' spacing={0} mt={1}>
                  <Text fontWeight={800} color={"#3c3c3c"} fontSize={"2xl"}>{student?.firstname + ' ' + student?.lastname}</Text>
                  <Text fontWeight={600} color={"#777777"}>{student?.matricule}</Text>
                  <Text fontWeight={600} color={"#777777"}>{student?.mail}</Text>
                </VStack>
                <Spacer />
                <HStack cursor={"default"}>
                  <Image src="https://d35aaqx5ub95lt.cloudfront.net/images/gems/45c14e05be9c1af1d7d0b54c6eed7eee.svg" />
                  <Text mt={1} fontSize={'xl'} fontWeight={900} color={"#1cb0f6"}>{student?.diamonds}</Text>
                </HStack>

                <HStack cursor={"default"}>
                  <Image position={'relative'} left={"7px"} height={'40px'} src="https://d35aaqx5ub95lt.cloudfront.net/images/goals/2b5a211d830a24fab92e291d50f65d1d.svg" />
                    <Text mt={1} fontSize={'xl'} fontWeight={900} color={"#ff9600"}>{student?.xp}</Text>
                </HStack>
              </Flex>
              
              <Box mt={5}>
                <Divider borderWidth={'2px'} mt={4} mb={4} />

                {/* <Text cursor={'default'} fontWeight={800} color={"#3c3c3c"} fontSize={"xl"}>Diamants</Text> */}
  
                {/* <Text cursor={'default'} fontWeight={800} color={"#3c3c3c"} fontSize={"xl"}>Progression dans le cours</Text>
  
                <Box m={2}>
                  <Tooltip placement='top' hasArrow label="Les variables">
                    <Square size={"25px"} bgColor={"blue.500"} display={"inline-block"}/>
                  </Tooltip>
                  <Tooltip placement='top' hasArrow label="Opérateurs en C">
                    <Square size={"25px"} bgColor={"blue.500"} display={"inline-block"}/>
                  </Tooltip>
                  <Tooltip placement='top' hasArrow label="Priorité des opérateurs">
                    <Square size={"25px"} bgColor={"blue.500"} display={"inline-block"}/>
                  </Tooltip>
                  <Tooltip placement='top' hasArrow label="If, Else">
                    <Square size={"25px"} bgColor={"blue.500"} display={"inline-block"}/>
                  </Tooltip>
                  <Tooltip placement='top' hasArrow label="While, do while, for">
                    <Square size={"25px"} bgColor={"blue.100"} display={"inline-block"}/>
                  </Tooltip>
                  <Tooltip placement='top' hasArrow label="Invariant graphique">
                    <Square size={"25px"} bgColor={"blue.100"} display={"inline-block"}/>
                  </Tooltip>
                  <Tooltip placement='top' hasArrow label="Les fonctions">
                    <Square size={"25px"} bgColor={"blue.100"} display={"inline-block"}/>
                  </Tooltip>
                </Box> */}
  
                <Text cursor={'default'} mt={5} fontWeight={800} color={"#3c3c3c"} fontSize={"xl"}>Challenges</Text>

                {!summaryFetched &&
                  <VStack mt={4} align='center'>
                    <Spinner
                      thickness='5px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                      size='xl'
                    />
                  </VStack>
                }

                {summaryFetched && challengesSummary.length == 0 &&
                  <VStack mt={4} align='center'>
                    <Text fontWeight={700} color={"#4b4b4b"}>Aucun challenge publié</Text>
                  </VStack>
                }

                {summaryFetched && challengesSummary.length > 0 &&
                  <Accordion allowToggle mt={4}>
                    {
                      challengesSummary.map((challenge, index) => (
                        <AccordionItem borderBottomWidth={'2px'} key={index}>
                          <AccordionButton>
                            <Box as='span' flex='1' textAlign='left'>
                              <VStack align='start' spacing={0}>
                                <Text color={"#4b4b4b"} fontWeight={700}>{challenge.title}</Text>
                                {challenge.joker && 
                                  <Tooltip label="Joker utilisé" placement="left" p={3} fontWeight={700}>
                                    <Text><i className="em em-black_joker"></i></Text>
                                  </Tooltip>
                                }
                                {!challenge.joker && <Text color={challenge.mark >= 10 ? "green.500" : "red.500"} fontWeight={600}>{challenge.mark}/20</Text>}
                              </VStack>
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel pb={4}>
                            <TableContainer width={"100%"}>
                              <Table variant='simple' border={"2pt solid #e5e5e5"} style={{borderRadius:"90px"}}>
                                <Thead borderBottom={"2pt solid #e5e5e5"}>
                                  <Tr cursor={'default'}>
                                    <Th>
                                      <Text 
                                        cursor={"default"} 
                                        fontWeight={700} 
                                        color={'#AFAFAF'} 
                                        fontSize={'sm'}>
                                          Date
                                      </Text>
                                    </Th>
                                    <Th isNumeric>
                                      <Text 
                                        cursor={"default"} 
                                        fontWeight={700} 
                                        color={'#AFAFAF'} 
                                        fontSize={'sm'}>
                                          Cert.
                                      </Text>
                                    </Th>
                                    <Th isNumeric>
                                      <Text 
                                        cursor={"default"} 
                                        fontWeight={700} 
                                        color={'#AFAFAF'} 
                                        fontSize={'sm'}>
                                          Happy hours
                                      </Text>
                                    </Th>
                                    <Th isNumeric>
                                      <Text 
                                        cursor={"default"} 
                                        fontWeight={700} 
                                        color={'#AFAFAF'} 
                                        fontSize={'sm'}>
                                          Points
                                      </Text>
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {challenge.submissions.length == 0 &&
                                    <Tr>
                                      <Td colSpan={4} textAlign={'center'}>
                                        <Text 
                                          cursor={"default"} 
                                          fontWeight={700} 
                                          color={'#AFAFAF'} 
                                          fontSize={'sm'}>
                                            Aucune soumission
                                        </Text>
                                      </Td>
                                    </Tr>
                                  }
                                  {
                                    challenge.submissions.map((submission, index:number) => (
                                      <Tr cursor={'default'} key={index}>
                                        <Td>
                                          <Text 
                                            cursor={"default"} 
                                            fontWeight={700} 
                                            color={'#777'} 
                                            fontSize={'sm'}>
                                              {moment(submission.date).format('DD/MM/YYYY à HH:mm:ss')}
                                          </Text>
                                        </Td>
                                        <Td isNumeric>
                                          <Text 
                                            cursor={"default"} 
                                            fontWeight={700} 
                                            color={'#AFAFAF'} 
                                            fontSize={'sm'}>
                                              {submission.evaluative ? <FontAwesomeIcon color='green' icon={faCheck} /> : <FontAwesomeIcon color='red' icon={faXmark} />}
                                          </Text>
                                        </Td>
                                        <Td isNumeric>
                                          <Text 
                                            cursor={"default"} 
                                            fontWeight={700} 
                                            color={'#AFAFAF'} 
                                            fontSize={'sm'}>
                                              {submission.happyHours ? <FontAwesomeIcon color='green' icon={faCheck} /> : <FontAwesomeIcon color='red' icon={faXmark} />}
                                          </Text>
                                        </Td>
                                        <Td isNumeric>
                                          <Text 
                                            cursor={"default"} 
                                            fontWeight={700} 
                                            color={submission.mark >= 10 ? 'green.500' : 'red.500'} 
                                            fontSize={'sm'}>
                                              {submission.mark}/20
                                          </Text>
                                        </Td>
                                      </Tr>
                                    ))
                                  }
                                </Tbody>
                              </Table>
                            </TableContainer>
                      </AccordionPanel>
                    </AccordionItem>
                  ))}
                  </Accordion>
                }

              </Box>
            </DrawerBody>
  
            {/* <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Fermer
              </Button>
            </DrawerFooter> */}
          </DrawerContent>
        </Drawer>
      </>
    )
  }