import { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { IChallenge, IChallengeSummary } from "../types";

export const getChallengesSummaries = async (matricule:string): Promise<AxiosResponse<IChallengeSummary[]>> => {
	try {
		const status: AxiosResponse<IChallengeSummary[]> = await API.get(
            `${API_URL}/challenges/summaries/${matricule}`
        );

        return status;
	} catch (error) {
		throw error;
	}
};

export const getChallenges = async (): Promise<AxiosResponse<IChallenge[]>> => {
	try {
		const status: AxiosResponse<IChallenge[]> = await API.get(
            `${API_URL}/challenges`
        );

        return status;
	} catch (error) {
		throw error;
	}
};

export const getChallengeStats = async (challengeId:string): Promise<AxiosResponse<any[]>> => {
	try {
		const status: AxiosResponse<any[]> = await API.get(
            `${API_URL}/challenges/stats/${challengeId}`
        );

        return status;
	} catch (error) {
		throw error;
	}
};

export const getChallengesDistribution = async (): Promise<AxiosResponse<any[]>> => {
	try {
		const status: AxiosResponse<any[]> = await API.get(
			`${API_URL}/challenges/distribution`
		);

		return status;
	} catch (error) {
		throw error;
	}
}