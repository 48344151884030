import axios, { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { IChapter, ICourse, IMcq, IModule, ISection, IUser, StepType } from "../types";

export const getMyCourses = async (): Promise<AxiosResponse<ICourse[]>> => {
	try {
		const status: AxiosResponse<ICourse[]> = await API.get(
            `${API_URL}/courses`
        );

        return status;
	} catch (error) {
		throw error;
	}
};

export const getChaptersByCourse = async (course_code:string): Promise<AxiosResponse<IChapter[]>> => {
	try {
		const status: AxiosResponse<IChapter[]> = await API.get(
            `${API_URL}/courses/${course_code}/chapters`
        );

        return status;
	} catch (error) {
		throw error;
	}
};

export const getSectionsByChapter = async (chapter_id:string): Promise<AxiosResponse<ISection[]>> => {
	try {
		const status: AxiosResponse<ISection[]> = await API.get(
            `${API_URL}/chapters/${chapter_id}/sections`
        );

        return status;
	} catch (error) {
		throw error;
	}
};

export const getQuestionsBySection = async (section_id:string): Promise<AxiosResponse<IMcq[]>> => {
	try {
		const status: AxiosResponse<IMcq[]> = await API.get(
			`${API_URL}/sections/questions/${section_id}`
		);

		return status;
	} catch (error) {
		throw error;
	}
}

export const createSection = async (chapter_id:string): Promise<AxiosResponse<ISection>> => {
	try {
		const status: AxiosResponse<ISection> = await API.post(
			`${API_URL}/sections/new`, {chapter_id: chapter_id}
		);

		return status;
	} catch (error) {
		throw error;
	}
}

export const updateSection = async (section:ISection): Promise<AxiosResponse<ISection>> => {
	try {
		const status: AxiosResponse<ISection> = await API.put(
			`${API_URL}/sections/${section._id}`,
			section
		);

		return status;
	} catch (error) {
		throw error;
	}
}

export const newQuestion = async (): Promise<AxiosResponse<IMcq>> => {
	try {
		const status: AxiosResponse<IMcq> = await API.post(
			`${API_URL}/mcq/new`
		);

		return status;
	} catch (error) {
		throw error;
	}
}

export const deleteQuestion = async (question_id:string): Promise<AxiosResponse<IMcq>> => {
	try {
		const status: AxiosResponse<IMcq> = await API.delete(
			`${API_URL}/mcq/${question_id}`
		);

		return status;
	} catch (error) {
		throw error;
	}
}

export const updateQuestion = async (question:IMcq): Promise<AxiosResponse<IMcq>> => {
	try {
		const status: AxiosResponse<IMcq> = await API.put(
			`${API_URL}/mcq/${question._id}`,
			question
		);

		return status;
	} catch (error) {
		throw error;
	}
}

export const getCourseStudents = async (course_code:string): Promise<AxiosResponse<IUser[]>> => {
	try {
		const status: AxiosResponse<IUser[]> = await API.get(
			`${API_URL}/courses/${course_code}/students`
		);

		return status;
	} catch (error) {
		throw error;
	}
}

export const getCourseTeachers = async (course_code:string): Promise<AxiosResponse<IUser[]>> => {
	try {
		const status: AxiosResponse<IUser[]> = await API.get(
			`${API_URL}/courses/${course_code}/teachers`
		);

		return status;
	} catch (error) {
		throw error;
	}
}