import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Center,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  AbsoluteCenter,
  Spacer,
  Tooltip,
  Fade,
  Collapse,
} from '@chakra-ui/react';
import { MobileNav } from '../layout/MobileNav';
import { SidebarContent } from '../layout/SidebarContent';
import { IModule, IStep, LogAction, StepState, StepType } from '../types';
import { getAllModules } from '../API/module';
import { StepTile } from './StepTile';
import { openChest } from '../API/step';
import diamonds_chest from '../resources/diamonds_chest.svg';
import target_img from '../resources/target.svg';
import team_img from '../resources/team.svg';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Logger } from '../Logger';
import { TabContext } from '../TabContext';
import {Image as ChakraImage} from '@chakra-ui/react';
import tac_sound from '../resources/sounds/tac.wav';


const TutoStep1: React.FunctionComponent<{nextCallback:Function}> = ({nextCallback}) => {
  const navigate = useNavigate();

  return <><Box height={'100px'} />
        
  <Center>
    <Box width={'700px'}>
      <Text mb={5} fontWeight={900} color={'#0bc5ea'} fontSize={'3xl'}>Bienvenue sur le Progress Tracker de CAFÉ !</Text>
      <Text textAlign={'justify'} fontWeight={600} fontSize={'xl'}>Que dirais-tu d'un rapide tutoriel pour t'expliquer comment fonctionne cette plateforme ? Tu peux revenir sur cette page plus tard si tu le souhaites.</Text>
    </Box>
  </Center>

  <Center mt={5}>
    <Box width={'700px'}>
      <Flex>
        <Box
          onClick={() => {
            navigate('/');
          }}
          cursor={'pointer'}
          border={'2pt solid #EF5555'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#EF5555'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              PLUS TARD...
      </Box>
      <Spacer />
        <Box
          onClick={() => {nextCallback();}}
          cursor={'pointer'}
          border={'2pt solid #93D333'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#93D333'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              C'EST PARTI !
      </Box>
      </Flex>
    </Box>
  </Center></>
}

const TutoStep2: React.FunctionComponent<{backCallback:Function, nextCallback:Function}> = ({backCallback, nextCallback}) => {
  const [showMcqAlert1, setShowMcqAlert1] = useState(true);

  return <><Box height={'100px'} />
        
  <Center>
    <Box width={'700px'}>
      <Text mb={5} fontWeight={900} color={'#0bc5ea'} fontSize={'3xl'}>Un suivi tout au long de l'année...</Text>
      <Text textAlign={'justify'} fontWeight={600} fontSize={'xl'}>Le <span style={{fontWeight:800}}>Progress Tracker</span> te permet de t'entrainer sur la matière de ton cours d'Introduction à la programmation grâce à des petites épreuves qui sont déverrouillées tout au long de l'année.</Text>
    </Box>
  </Center>

  <Center mt={5}>
    <Box rounded={"2xl"} bgColor={"#1DB0F6"} color={"white"} p={5} width={"700px"}>
      <Center>
        <Text cursor={'default'} fontWeight={900} fontSize={"2xl"}>
            Module 1 : Les Variables
        </Text>
      </Center>
    </Box>
  </Center>

  <Center mt={5}>
    <Tooltip 
      zIndex={1}
      hasArrow
      ps={4} pe={4} pt={2} pb={2} 
      ms={3}
      label={'Clique sur ce bouton !'}
      fontWeight={800}
      fontSize={'md'}
      placement={"right"}
      borderRadius={'lg'}
      bgColor={'#1DB0F6'}
      display={(showMcqAlert1) ? 'block' : 'none'}
      isOpen>
    <Box onClick={() => {setShowMcqAlert1(false);}}>
        <StepTile step={{
              _id: '1', 
              title: "Exemple d'étape terminée", 
              description: "Je suis une étape terminée ! Si tu joues à mes QCM, tu gagneras des points d'expérience !", 
              type: StepType.EXERCISE, 
              state: StepState.COMPLETED,
              reward:100
              }} index={0} hasFlag={false} start_callback={async (stepId: string) => {  }} 
              moduleIndex={0}
        />
    </Box>
      </Tooltip>
  </Center>

  <Center mt={2}>
    <Box position={'relative'} left={"35px"}>
      <StepTile step={{
            _id: '2', 
            title: "Exemple d'étape ouverte", 
            description: "Et moi, je suis une étape ouverte ! Si tu joues à mes QCM, tu gagneras 1000 diamants :D", 
            type: StepType.EXERCISE, 
            state: StepState.UNLOCKED,
            reward:1000
            }} index={0} hasFlag={false} start_callback={async (stepId: string) => {  }}
            moduleIndex={0} 
      />
    </Box>
  </Center>

  <Center mt={2}>
    <Box>
      <StepTile step={{
            _id: '3', 
            title: "Exemple d'étape", 
            description: "Je suis une étape ! Si tu joues à mes QCM, tu gagneras 1000 diamants !", 
            type: StepType.REWARD, 
            state: StepState.LOCKED,
            reward:1000
            }} index={0} hasFlag={false} start_callback={async (stepId: string) => {  }} 
            moduleIndex={0}
      />
    </Box>
  </Center>

  <Center mt={5}>
    <Box width={'700px'}>
      <Text mt={2} textAlign={'justify'} fontWeight={600} fontSize={'xl'}>Ton cours est composé de <span style={{fontWeight:800}}>modules</span> qui sont débloqués après chaque cours, et chaque module est composé <span style={{fontWeight:800}}>d'étapes</span> qui te permettent de tester tes connaissances.</Text>
    </Box>
  </Center>

  <Center mt={5}>
    <Box width={'700px'}>
      <Flex>
        <Box
          onClick={() => {
            backCallback();
          }}
          cursor={'pointer'}
          border={'2pt solid #6A7073'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#6A7073'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              RETOUR
      </Box>
      <Spacer />
        <Box
          onClick={() => {
            nextCallback();
          }}
          cursor={'pointer'}
          border={'2pt solid #93D333'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#93D333'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              SUITE
      </Box>
      </Flex>
    </Box>
  </Center></>
}

const TutoStep3: React.FunctionComponent<{backCallback:Function, nextCallback:Function}> = ({backCallback, nextCallback}) => {
  return <><Box height={'100px'} />
        
  <Center>
    <Box width={'700px'}>
      <Text mb={5} fontWeight={900} color={'#0bc5ea'} fontSize={'3xl'}>Diamants et points d'expérience</Text>

      <Flex>
        <ChakraImage draggable={false} className='hvr-grow' src={diamonds_chest} alt="target" width={'170px'} />
        <Text textAlign={'justify'} fontWeight={600} fontSize={'xl'} mt={5} ms={3}>
          Lorsque tu termines une étape pour la première fois, tu gagnes des <span style={{fontWeight:800, color:"#24BAFE"}}>diamants</span>... Et si tu collectes assez de diamants d'ici la fin de l'année, tu pourras les échanger contre <span style={{fontWeight:800, color:"#24BAFE"}}>un bonus à l'examen final</span> !
        </Text>
      </Flex>

      <Flex mt={4}>
        <Text textAlign={'justify'} fontWeight={600} fontSize={'xl'} mt={5} me={3}>
          En plus des diamants, refaire des étapes pour t'entrainer te donne des <span style={{fontWeight:800, color:"#FFD100"}}>points d'XP</span>. Les points d'XP te permettent de garder une trace de ta progression, mais ne sont pas utilisés pour obtenir des bonus à l'examen.
        </Text>
        <ChakraImage draggable={false} className='hvr-grow' src={"https://d35aaqx5ub95lt.cloudfront.net/images/goals/2b5a211d830a24fab92e291d50f65d1d.svg"} alt="target" width={'350px'} />
      </Flex>
    </Box>
  </Center>

  <Center mt={5}>
    <Box width={'700px'}>
      <Flex>
        <Box
          onClick={() => {
            backCallback();
          }}
          cursor={'pointer'}
          border={'2pt solid #6A7073'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#6A7073'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              RETOUR
      </Box>
      <Spacer />
        <Box
          onClick={() => {
            nextCallback();
          }}
          cursor={'pointer'}
          border={'2pt solid #93D333'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#93D333'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              TROP BIEN
      </Box>
      </Flex>
    </Box>
  </Center></>
}

const TutoStep4: React.FunctionComponent<{backCallback:Function, nextCallback:Function}> = ({backCallback, nextCallback}) => {
  const navigate = useNavigate();
  
  return <><Box height={'100px'} />
        
  <Center>
    <Box width={'700px'}>
      <Text mb={5} fontWeight={900} color={'#0bc5ea'} fontSize={'3xl'}>Happy Hours !</Text>

      <Flex>
        <ChakraImage draggable={false} src={'https://d35aaqx5ub95lt.cloudfront.net/images/icons/47112600732328e46768927036578c8b.svg'} width={'200px'} />
        <Text textAlign={'justify'} fontWeight={600} fontSize={'xl'} mt={5} ms={3}>
          Si tu termines des étapes durant les <span style={{fontWeight:800, color:"#FFC800"}}>HAPPY HOURS</span>, tu gagnes <span style={{fontWeight:800}}>le double de diamants</span> ! Les happy hours d'un module sont actives pendant 1 semaine après sa publication... Ça veut dire qu'il ne faut pas trop tarder pour t'entrainer lorsqu'un module est débloqué !
        </Text>
      </Flex>

    </Box>
  </Center>

  <Center mt={5}>
    <Box width={'700px'}>
      <Flex>
        <Box
          onClick={() => {
            backCallback();
          }}
          cursor={'pointer'}
          border={'2pt solid #6A7073'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#6A7073'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              RETOUR
      </Box>
      <Spacer />
        <Box
          onClick={() => {
            nextCallback();
          }}
          cursor={'pointer'}
          border={'2pt solid #93D333'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#93D333'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              OK !
      </Box>
      </Flex>
    </Box>
  </Center></>
}

const TutoStep5: React.FunctionComponent<{backCallback:Function, nextCallback:Function}> = ({backCallback, nextCallback}) => {
  const navigate = useNavigate();
  
  return <><Box height={'100px'} />
        
  <Center>
    <Box width={'700px'}>
      <Text mb={5} fontWeight={900} color={'#0bc5ea'} fontSize={'3xl'}>Enjoy !</Text>
      
      <Text textAlign={'justify'} fontWeight={600} fontSize={'xl'} mt={5} ms={3}>
        L'équipe éducative du cours d'Introduction à la programmation reste à ta disposition pour répondre à tes questions et t'aider à progresser. N'hésite pas à nous contacter si tu as des questions ou des suggestions !
      </Text>

      <ChakraImage draggable={false} src={team_img} width={'100%'} />

    </Box>
  </Center>

  <Center mt={5}>
    <Box width={'700px'}>
      <Flex>
        <Box
          onClick={() => {
            backCallback();
          }}
          cursor={'pointer'}
          border={'2pt solid #6A7073'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#6A7073'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              RETOUR
      </Box>
      <Spacer />
        <Box
          onClick={() => {
            // nextCallback();
            navigate('/');
          }}
          cursor={'pointer'}
          border={'2pt solid #93D333'} 
          p={3}
          mt={3}
          className="hvr-grow"
          bgColor={'#93D333'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
              C'EST PARTI !
      </Box>
      </Flex>
    </Box>
  </Center></>
}

const Tuto: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const currentTab = useContext(TabContext).currentTab;
    const [step, setStep] = useState<number>(1);

    useEffect(() => {
      // Preload images
      const img = new Image();
      img.src = diamonds_chest;
      img.src = target_img;
      img.src = team_img;
    }, []);

    const nextStep = () => {
      setStep(step+1);
      const audio = new Audio(tac_sound);
      audio.play();
      const logger = Logger.getInstance();
      logger.log(currentTab, LogAction.NEXT_TUTORIAL, { step });
    }
    const backStep = () => {
      if (step === 1) {
        navigate('/');
      }
      setStep(step-1);
      const audio = new Audio(tac_sound);
      audio.play();
      const logger = Logger.getInstance();
      logger.log(currentTab, LogAction.BACK_TUTORIAL, { step });
    }
    
    // LOGGER
    useEffect(() => {
      const logger = Logger.getInstance();
      const url = window.location.href;
      logger.log(currentTab, LogAction.OPEN_PAGE, { url });
    }, []);

    return (
      <Box minH="100vh" bg={"#131f24"} color={'white'} cursor={'default'}>
        <Box ms={5} me={5}>

          {step === 1 && <TutoStep1 nextCallback={nextStep} />}

          {step === 2 && <TutoStep2 backCallback={backStep} nextCallback={nextStep} />}

          {step === 3 && <TutoStep3 backCallback={backStep} nextCallback={nextStep} />}

          {step === 4 && <TutoStep4 backCallback={backStep} nextCallback={nextStep} />}

          {step === 5 && <TutoStep5 backCallback={backStep} nextCallback={nextStep} />}

        </Box>
      </Box>
    );
  }
  export default Tuto;