import {
  Box,
  CloseButton,
  Flex,
  Text,
  BoxProps,
} from '@chakra-ui/react';
import { LinkItems } from './LinkItems';
import { NavItem } from './NavItem';

interface SidebarProps extends BoxProps {
    onClose: () => void;
}

export const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
    return (
      <Box
        transition="3s ease"
        bg={"#f7f7f7"}
        borderRight="2px"
        borderRightColor={"#e5e5e5"}
        w={{ base: 'full', md: 60 }}
        pos="fixed"
        h="full"
        {...rest}>
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Text fontSize="xl" fontWeight={900} color={"#ff4b4b"} cursor={"default"}>
            Student Tracker
          </Text>
          <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
        </Flex>
        {LinkItems.map((link) => (
          <NavItem 
            key={link.path} 
            path={link.path} 
            icon={link.icon} 
            external={link.external}>
            {link.name}
          </NavItem>
        ))}
      </Box>
    );
};