import axios, { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { IModule } from "../types";

/**
 * GET request to the server to get all modules.
 */
export const getAllModules = async (): Promise<AxiosResponse<IModule[]>> => {
	try {
		const modules: AxiosResponse<IModule[]> = await API.get(
			`${API_URL}/modules`
		);
		return modules;
	} catch (error) {
		throw error;
	}
};
/**
 * GET request to the server to get a module by its id.
 */
export const getModuleById = async (id:string): Promise<AxiosResponse<IModule>> => {
    try {
        const module: AxiosResponse<IModule> = await API.get(
            `${API_URL}/modules/${id}`
        );
        return module;
    } catch (error) {
        throw error;
    }
}

/**
 * GET request to the server to get a module by its id.
 */
export const getModuleBySlug = async (slug:string): Promise<AxiosResponse<IModule>> => {
    try {
        const module: AxiosResponse<IModule> = await API.get(
            `${API_URL}/modules/slug/${slug}`
        );
        return module;
    } catch (error) {
        throw error;
    }
}

export const getModulesByCourse = async (course:string): Promise<AxiosResponse<IModule[]>> => {
    try {
        const modules: AxiosResponse<IModule[]> = await API.get(
            `${API_URL}/modules/course/${course}`
        );
        return modules;
    } catch (error) {
        throw error;
    }
}

export const createModule = async (course:string): Promise<AxiosResponse<IModule>> => {
    try {
        const module: AxiosResponse<IModule> = await API.post(
            `${API_URL}/modules`,
            {course}
        );
        return module;
    }
    catch (error) {
        throw error;
    }
}

export const updateModule = async (id:string, module:IModule): Promise<AxiosResponse<IModule>> => {
    try {
        const updatedModule: AxiosResponse<IModule> = await API.put(
            `${API_URL}/modules/${id}`,
            module
        );
        return updatedModule;
    }
    catch (error) {
        throw error;
    }
}

export const deleteModule = async (id:string): Promise<AxiosResponse<IModule>> => {
    try {
        const deletedModule: AxiosResponse<IModule> = await API.delete(
            `${API_URL}/modules/${id}`
        );
        return deletedModule;
    }
    catch (error) {
        throw error;
    }
}