import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Divider, Flex, FormControl, FormLabel, Image, Input, Spacer, Text } from "@chakra-ui/react"
import { IChapter, IMcq, ISection } from "../types";
import { createRef, useEffect, useState } from "react";
import { deleteQuestion, getQuestionsBySection, newQuestion, updateSection } from "../API/course";
import { PoolQuestionEditor } from "./PoolQuestionEditor";
import { FaTrash } from "react-icons/fa";
import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * 
export interface ModuleEditorProps {
    module:IModule;
    delete_callback: () => void;
    update_title_callback: (new_title: string) => void;
}

const ModuleEditor: React.FunctionComponent<ModuleEditorProps> = ({module, delete_callback, update_title_callback}) => {
 * 
 */

export interface PoolSectionProps {
    section_index:number;
    section:ISection;
}

export const PoolSection: React.FunctionComponent<PoolSectionProps> = ({section_index, section}) => {

    const [questions, setQuestions] = useState<IMcq[]>([]);
    const [currentSection, setCurrentSection] = useState<ISection>(section);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getQuestionsBySection(currentSection._id).then((res) => {
            setQuestions(res.data);
            setLoading(false);
        });
    }, []);

    const add_question = () => {
        // Add in DB, get the result, update the questions, and update the section
        newQuestion().then((res) => {
            let new_question:IMcq = res.data;
            setQuestions([...questions, new_question]);

            let new_section = {...currentSection};
            new_section.questions.push(new_question._id!);
            setCurrentSection(new_section);

            updateSection(new_section);
        });
    }

    const delete_question = (index:number) => {
        let q_to_delete = questions[index];
        // Delete in DB
        deleteQuestion(q_to_delete._id!).then(() => {
            let new_questions = questions.filter((_, i) => i !== index);
            setQuestions(new_questions);

            let new_section = {...currentSection};
            new_section.questions = new_section.questions.filter((_, i) => i !== index);
            setCurrentSection(new_section);

            updateSection(new_section);
        });
    }

    const titleRef = createRef<HTMLInputElement>();
    const update_title = () => {
        let new_title = titleRef.current?.value || '';
        let new_section = {...currentSection};
        new_section.title = new_title;
        setCurrentSection(new_section);

        updateSection(new_section);
    }

    const delete_section = () => {
        // Delete in DB
        // Delete all questions in the section
        // Delete the section from the chapter
        // Delete the section from the course
        // Delete the section from the user's courses
    }

    return <AccordionItem key={1} border={"2px solid #E5E5E5"} rounded={'xl'} mb={3}>
        <AccordionButton p={5}>
            <Box as='span' flex='1' textAlign='left' pb={0} mb={0}>
                <Text color={'#4B4B4B'} fontWeight={700} pb={0} mb={0}>Section {section_index+1} - {currentSection.title}</Text>
                <Spacer />
                <Text fontSize={'sm'} color={'#4B4B4B'} fontWeight={600} pb={0} mb={0}>{questions.length} questions</Text>
            </Box>
            <AccordionIcon />
        </AccordionButton>
        
        {!loading &&
        <AccordionPanel p={'30px'}>

            <FormControl mb={4}>
                <FormLabel color={'#AFAFAF'} fontSize={'sm'} fontWeight={800}>TITRE DE LA SECTION</FormLabel>
                <Input 
                    width={'100%'} p={5} rounded={'lg'} fontWeight={600} color={"#777777"} bgColor={"#F7F7F7"} borderWidth={'2px'} borderColor={"#E5E5E5"} me={4} 
                    ref={titleRef} placeholder='e.g., Blocs, variables et Instructions' onBlur={update_title} 
                    defaultValue={currentSection.title}
                    />
            </FormControl>


            {questions.map((question, index) => (
                <PoolQuestionEditor key={question._id} question_index={index} question={question} delete_callback={() => delete_question(index)} />
            ))}

            {questions.length === 0 && <Box mt={'40px'}>
                <Center>
                    <Image draggable={false} width={'90px'} src="https://schools-cdn.duolingo.com/images/7568e088430bd955149ed4d8d3605131.svg" alt="No modules" />
                </Center>
                <Center>
                    <Text cursor={'default'} fontSize={"md"} m={5} color={"#4B4B4B"} fontWeight={800}>Cette section est vide</Text>
                </Center>
            </Box>
            }

            <Flex>
                <Button mb={4} mt={4} rightIcon={<FaTrash />} size={"md"} borderWidth={"2px"} borderColor={'#D73B3B'} onClick={delete_section} colorScheme='red'>
                    <Text fontWeight={700}>Supprimer la section</Text>
                </Button>
                <Spacer />
                <Button me={4} mb={4} rightIcon={<FontAwesomeIcon icon={faFileLines} />} size={'md'} variant='outline' borderWidth={"2px"} borderColor={"#E5E5E5"} mt={4} onClick={add_question}>
                    <Text fontWeight={700}>Nouvelle question</Text>
                </Button>
            </Flex>
        </AccordionPanel>
        }

        {loading &&
        <AccordionPanel p={'30px'} pt={0}>

            <Center>
                <Box width={'200px'} opacity={0.9}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="#FF4B4B" stroke="#FF4B4B" strokeWidth="14" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="2" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="#FF4B4B" stroke="#FF4B4B" strokeWidth="14" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="2" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="#FF4B4B" stroke="#FF4B4B" strokeWidth="14" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="2" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>
                </Box>
            </Center>
            
        </AccordionPanel>
        }
    </AccordionItem>
    
}