import axios, { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { IEarnings, IMcq, IModule, IStep, IStepInstance, StepType } from "../types";

/**
 * GET request to the server to get all modules.
 */
export const completeStep = async (stepId:string): Promise<AxiosResponse<any>> => {
	try {
		const status: AxiosResponse<any> = await API.post(
            `${API_URL}/steps/${stepId}/complete`
        );

        return status;
	} catch (error) {
		throw error;
	}
};

export const openChest = async (stepId:string): Promise<AxiosResponse<any>> => {
	try {
		const status: AxiosResponse<any> = await API.post(
            `${API_URL}/steps/${stepId}/chest`
        );

        return status;
	} catch (error) {
		throw error;
	}
};

export const getStepsByModule = async (moduleId:string): Promise<AxiosResponse<IModule>> => {
	try {
		const steps: AxiosResponse<IModule> = await API.get(
			`${API_URL}/steps/module/${moduleId}`
		);
		return steps;
	} catch (error) {
		throw error;
	}
}

export const createStep = async (moduleId:string, stepType:StepType, index:number): Promise<AxiosResponse<IStep>> => {
	try {
		const step: AxiosResponse<IStep> = await API.post(
			`${API_URL}/steps`,
			{moduleId, type: stepType}
		);
		return step;
	}
	catch (error) {
		throw error;
	}
}

export const updateStep = async (id:string, step:IStep): Promise<AxiosResponse<IStep>> => {
	try {
		const updatedStep: AxiosResponse<IStep> = await API.put(
			`${API_URL}/steps/${id}`,
			step
		);
		return updatedStep;
	}
	catch (error) {
		throw error;
	}
}

export const deleteStep = async (id:string): Promise<AxiosResponse<any>> => {
	try {
		const status: AxiosResponse<any> = await API.delete(
			`${API_URL}/steps/${id}`
		);
		return status;
	}
	catch (error) {
		throw error;
	}
}

export const getStepInstance = async (module_slug:string, step_index:number): Promise<AxiosResponse<IStepInstance>> => {
	try {
		const steps: AxiosResponse<IStepInstance> = await API.get(
			`${API_URL}/steps/instance/${module_slug}/${step_index}`
		);
		return steps;
	} catch (error) {
		throw error;
	}
}

export const correctQuestion = async (stepInstanceId:string, answerIndex:number)
	: Promise<AxiosResponse<{correct:boolean, feedback:string}>> => {
	try {
		const resp: AxiosResponse<{correct:boolean, feedback:string}> = await API.get(
			`${API_URL}/mcq/correction/${stepInstanceId}/${answerIndex}`
		);
		return resp;
	} catch (error) {
		throw error;
	}
}

export const skipQuestion = async (stepInstanceId:string)
	: Promise<AxiosResponse<{correct:boolean, feedback:string}>> => {
	try {
		const resp: AxiosResponse<{correct:boolean, feedback:string}> = await API.get(
			`${API_URL}/mcq/correction/${stepInstanceId}/-1`
		);
		return resp;
	} catch (error) {
		throw error;
	}
}

export const getEarnings = async (stepInstanceId:string)
	: Promise<AxiosResponse<IEarnings>> => {
	try {
		const resp: AxiosResponse<IEarnings> = await API.get(`${API_URL}/mcq/earnings/${stepInstanceId}`
		);
		return resp;
	} catch (error) {
		throw error;
	}
}

export const getQuestionsFromStep = async (stepId:string) : Promise<AxiosResponse<IMcq[]>> => {
	try {
		const resp: AxiosResponse<IMcq[]> = await API.get(`${API_URL}/mcq/questions/${stepId}`);
		return resp;
	} catch (error) {
		throw error;
	}
}

export const getRemainingQuestionsNumberByStep = async (stepId:string) : Promise<AxiosResponse<number>> => {
	try {
		const resp: AxiosResponse<number> = await API.get(`${API_URL}/steps/${stepId}/remainingQuestions`);
		return resp;
	}
	catch (error) {
		throw error;
	}
}