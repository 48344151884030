import { AbsoluteCenter, Box, Button, Center, Circle, Divider, Flex, Grid, HStack, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Progress, Skeleton, Spacer, Table, TableContainer, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useDisclosure } from "@chakra-ui/react";
import { MobileNav } from "./layout/MobileNav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faCheck, faRepeat, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { IEarnings, IModule, IStepInstance, LogAction } from "./types";
import { correctQuestion, getEarnings, getStepInstance, skipQuestion } from "./API/step";
import moment from "moment";
import { getModuleById, getModuleBySlug } from "./API/module";
import chest_open_gif from './resources/step/chest_open.gif';
import hourglass_gif from './resources/hourglass.gif';
import "./LessonStyle.css";
import { TabContext } from "./TabContext";
import { Logger } from "./Logger";
import plonk_sound from './resources/sounds/plonk.wav';
import tac_sound from './resources/sounds/tac.wav';
import lesson_end_sound from './resources/sounds/lesson_end.mp3';
import right_answer_sound from './resources/sounds/right_answer.mp3';
import wrong_answer_sound from './resources/sounds/wrong_answer.mp3';

interface TimesupModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const TimesupModal: React.FunctionComponent<TimesupModalProps> = ({isOpen, onClose}) => {
    const navigate = useNavigate();

    return <Modal isOpen={isOpen} onClose={onClose} size={'xl'} isCentered closeOnOverlayClick={false}>
    <ModalOverlay backdropFilter='auto'
      backdropBlur='4px' />
    <ModalContent p={4} cursor={'default'}>
      <ModalHeader fontWeight={800} fontSize={'2xl'}>Time's up !</ModalHeader>
      <ModalBody>
        <Center>
            <Image draggable={false} width={"150px"} src={hourglass_gif} alt={"Sablier"} />
        </Center>
        <Center>
            <Text>Les 10 minutes sont écoulées ! Si c'était ta première tentative, il n'y aura pas de diamants pour cette fois... Mais tu peux toujours retenter cette étape pour gagner des points d'XP.</Text>
        </Center>
      </ModalBody>

      <ModalFooter>
        <Box
            onClick={() => {
                navigate('/');
            }}
            cursor={'pointer'}
            border={'2pt solid #93D333'} 
            p={3}
            className="hvr-grow"
            bgColor={'#93D333'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
                OK...
        </Box>
      </ModalFooter>
    </ModalContent>
  </Modal>
}

const Lesson: React.FunctionComponent = () => {
    enum StepState {
        DEFAULT = "locked",
        SUCCESS = "unlocked",
        FAILURE = "completed",
        FINISH = "finish"
    }

    const [percent, setPercent] = useState(0);
    const { moduleSlug, stepIndex } = useParams();
    const MODULE_SLUG:string = moduleSlug || '';
    const [module, setModule] = useState<IModule|null>(null);
    const STEP_INDEX:number = parseInt(stepIndex || '0');
    const [stepInstance, setStepInstance] = useState<IStepInstance|null>(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [stepState, setStepState] = useState<string>(StepState.DEFAULT);
    const [remainingTime, setRemainingTime] = useState('10:00');
    const [timerSet, setTimerSet] = useState(false);
    const [deadlinePassed, setDeadlinePassed] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [loading, setLoading] = useState(true);
    const [earnings, setEarnings] = useState<IEarnings|null>(null);
    const [gettingEarnings, setGettingEarnings] = useState(false);
    const [exitWarning, setExitWarning] = useState(true);
    const navigate = useNavigate();
    const currentTab = useContext(TabContext).currentTab;

    useEffect(() => {
        const logger = Logger.getInstance();
        const url = window.location.href;
        logger.log(currentTab, LogAction.OPEN_PAGE, { url });
    }, []);

    useEffect(() => {
        // Fetch step instance for this user, this module, this step
        getStepInstance(MODULE_SLUG, STEP_INDEX).then((response) => {
            let stepInstanceData:IStepInstance = response.data;
            set_timer(stepInstanceData);

            // For each question, shuffle the questions but
            // set the attribute "originalIndex" to the original index
            // in order to display the answers in the correct order
            stepInstanceData.questions.forEach((question) => {
                question.answers.forEach((answer, index) => {
                    answer.originalIndex = index;
                });
                question.answers.sort(() => Math.random() - 0.5);
            });

            // Remove the slow-up-and-down class from the timer if more than 1 minute left
            const timerElement = document.getElementById('timer');
            if (timerElement && timerElement.classList.contains('slow-up-and-down') && moment.duration(moment(stepInstanceData.deadline).diff(new Date())).asSeconds() > 60) {
                timerElement.classList.remove('slow-up-and-down');
            }

            setStepInstance(stepInstanceData);
            setCurrentQuestionIndex(stepInstanceData.answers.length);
            setPercent(Math.ceil((stepInstanceData.answers.length / stepInstanceData.questions.length) * 100));
            setLoading(false);
        }, (error) => {
            setExitWarning(false);
            navigate('/');
        });
        getModuleBySlug(MODULE_SLUG).then((response) => {
            setModule(response.data);
        }).catch((error) => {
            setExitWarning(false);
            navigate('/');
        });
    }, []);

    useEffect(() => {
        if (deadlinePassed && stepState != StepState.FINISH) {
            timesup();
        }
    }, [deadlinePassed]);

    const handleButtonClick = () => {
        if (stepState == StepState.FINISH) {
            setExitWarning(false);
            navigate('/');
            return;
        }
        const userConfirmed = window.confirm("Attention ! Si tu quittes la page maintenant, le chrono ne s'arrêtera pas. Reviens vite !");
        if (userConfirmed) {
            setExitWarning(false);
            navigate('/');
        }
    };

    const set_timer = (stepInstance:IStepInstance) => {
        if (timerSet)
            return;

        const deadline:Date = new Date(stepInstance.deadline);

        // Use moment.js to calculate remaining time in format 'mm:ss'
        const timer = setInterval(() => {
            const now = new Date();
            const diff = moment.duration(moment(deadline).diff(now));
            if (diff.asSeconds() <= 0) {
                setRemainingTime('00:00');
                setTimerSet(true);
                clearInterval(timer);
                timesup();
            } else {
                setRemainingTime(diff.minutes().toString().padStart(2, '0') + ':' + diff.seconds().toString().padStart(2, '0'));
                setTimerSet(true);

                // If time is under 1 minute, add classname 'slow-up-and-down' to timer if not already present
                const timerElement = document.getElementById('timer');
                if (timerElement && diff.asSeconds() <= 60) {
                    if (!timerElement.classList.contains('slow-up-and-down')) {
                        timerElement.classList.add('slow-up-and-down');
                    }
                }
            }
        }, 1000);
    }

    const timesup = () => {
        if (stepState == StepState.FINISH)
            return;
        timesup_disclosure.onOpen();
        setDeadlinePassed(true);
    }

    const select_answer = (index:number) => {
        if (stepState != StepState.DEFAULT)
            return;
        setSelectedIndex(index);

        // Play tac sound
        const audio = new Audio(tac_sound);
        audio.play();
    }

    const validate_answer = () => {
        if (deadlinePassed) {
            navigate('/');
            return;
        }

        const logger = Logger.getInstance();
        logger.log(currentTab, LogAction.SUBMIT_ANSWER, { 
            step_id: stepInstance?._id || '',
            question_id: stepInstance?.questions[currentQuestionIndex]._id || '',
            answer_index: selectedIndex
         });

        // Get the original index of the selected answer
        const originalIndex = stepInstance?.questions[currentQuestionIndex].answers[selectedIndex].originalIndex || 0;

        correctQuestion(stepInstance?._id || '', originalIndex)
        .then((response) => {
            if (response.data.correct) {
                setStepState(StepState.SUCCESS);

                // Play right answer sound
                const audio = new Audio(right_answer_sound);
                audio.play();
            } else {
                setStepState(StepState.FAILURE);

                // Play wrong answer sound
                const audio = new Audio(wrong_answer_sound);
                audio.play();
            }
            if (!stepInstance)
                return;
            // Push the answer to the step instance
            const newStepInstance = {
                ...stepInstance,
                answers: [...stepInstance.answers, {question_id: stepInstance.questions[currentQuestionIndex]._id, answer_index: selectedIndex, correct: response.data.correct}]
            };
            setStepInstance(newStepInstance);
            setFeedback(response.data.feedback);

            // Update the progress bar
            setPercent(Math.ceil((newStepInstance.answers.length / newStepInstance.questions.length) * 100));
        }).catch((error) => {
            console.error(error);
        });
    }

    const next_question = () => {
        setSelectedIndex(-1);
        setStepState(StepState.DEFAULT);
        setCurrentQuestionIndex(currentQuestionIndex + 1);

        // Play plonk sound
        const audio = new Audio(plonk_sound);
        audio.play();

        // Check if it was the last question
        if (currentQuestionIndex + 1 == stepInstance?.questions.length) {
            // Set the step as completed
            setStepState(StepState.FINISH);
            setGettingEarnings(true);

            // Ask for earnings
            getEarnings(stepInstance?._id || '').then((response) => {
                setEarnings(response.data);
                setGettingEarnings(false);
            });

            // Play lesson end sound
            const audio = new Audio(lesson_end_sound);
            audio.play();

            // Log the completion of the step
            const logger = Logger.getInstance();
            logger.log(currentTab, LogAction.COMPLETE_STEP, { step_instance_id: stepInstance?._id || '' });
        } else {
            // Log the next question
            const logger = Logger.getInstance();
            logger.log(currentTab, LogAction.NEXT_STEP, { step_instance_id: stepInstance?._id || '' });
        }
    }

    const timesup_disclosure = useDisclosure();

    return <Box minH="100vh" bg={"#131f24"}>
    {/* <MobileNav full={true} onOpen={onOpen} /> */}
    <TimesupModal isOpen={timesup_disclosure.isOpen} onClose={timesup_disclosure.onClose} />

    {/* Loading screen */}
    {(loading || gettingEarnings) &&
        <Box bg={"#131f24"} width={'100%'} height={'100%'} position={'absolute'} zIndex={1000}>
            <AbsoluteCenter>
                <Box width={'200px'} opacity={0.9}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><rect fill="#87DCFF" stroke="#87DCFF" strokeWidth="14" width="30" height="30" x="25" y="50"><animate attributeName="y" calcMode="spline" dur="2" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="#87DCFF" stroke="#87DCFF" strokeWidth="14" width="30" height="30" x="85" y="50"><animate attributeName="y" calcMode="spline" dur="2" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="#87DCFF" stroke="#87DCFF" strokeWidth="14" width="30" height="30" x="145" y="50"><animate attributeName="y" calcMode="spline" dur="2" values="50;120;50;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>
                </Box>
            </AbsoluteCenter>
        </Box>
    }

    {/* Summary box */}
    {!gettingEarnings && stepState == StepState.FINISH &&
    <Box bg={"#131f24"} width={'100%'} height={'100%'} position={'absolute'} zIndex={1000}>
        <Center mt={"50px"}>
            <Box mb={1}>
                <Text cursor={'default'} color={'white'} fontSize={'3xl'} fontWeight={900}>{module?.title} - Étape {STEP_INDEX}</Text>
            </Box>
        </Center>
        <Center>
            <Box width={'700px'} bgColor={'white'} rounded={'3xl'} mt={3} shadow={'xl'}>
                <Center>
                    <Image draggable={false} width={"200px"} src={chest_open_gif} alt={"Coffre ouvert"} />
                </Center>
            </Box>
        </Center>

        <Center>
            <Box cursor={'default'} >
                {earnings &&
                <TableContainer width={"700px"}>
                    <Table variant='simple'>
                        <Thead>
                            <Tr cursor={'default'}>
                                <Th fontWeight={900} fontSize={'lg'} color={'white'}>Question</Th>
                                <Th position={'relative'} left={'15px'} isNumeric fontWeight={900} fontSize={'lg'} color={'#ff9600'}>XP <Image position={'relative'} top={'6px'} display={'inline'} draggable={false} src="https://d35aaqx5ub95lt.cloudfront.net/images/goals/2b5a211d830a24fab92e291d50f65d1d.svg" width={'30px'} /></Th>
                                {earnings.firstTime > 0 &&
                                    <Th isNumeric fontWeight={900} fontSize={'lg'} color={'#1cb0f6'}>DIAMANTS <Image ms={1} position={'relative'} top={'6px'} display={'inline'} draggable={false} src="https://d35aaqx5ub95lt.cloudfront.net/images/gems/45c14e05be9c1af1d7d0b54c6eed7eee.svg" width={'22px'} /></Th>
                                }
                            </Tr>
                        </Thead>
                        <Tbody>
                        {earnings?.summary.map((item, index) => {
                            return <Tr color={'white'}>
                                <Td 
                                    dangerouslySetInnerHTML={{__html:item.statement}}
                                    color={item.correct ? '#79B933' : '#EF5555'} fontWeight={700} maxWidth={'400px'}
                                    overflow={'scroll'}
                                    // set scrollbar to be invisible
                                    css={{
                                        '&::-webkit-scrollbar': {
                                            display: 'none'
                                        }
                                    }}>
                                </Td>
                                {item.correct && item.previouslyCorrect &&
                                    <Tooltip p={2} fontWeight={800} bgColor={'#ff9600'} 
                                        label="Tu as déjà répondu juste à cette question auparavant." 
                                        placement="right" hasArrow>
                                        <Td fontWeight={800} fontSize={'xl'} color={'#ff9600'} isNumeric>+{item.xp}</Td>
                                    </Tooltip>
                                }
                                {item.correct && !item.previouslyCorrect &&
                                    <Tooltip p={2} fontWeight={800} bgColor={'#ff9600'} 
                                        label="C'est la première fois que tu réponds juste à cette question, bien joué !" 
                                        placement="right" hasArrow>
                                        <Td fontWeight={800} fontSize={'xl'} color={'#ff9600'} isNumeric>+{item.xp}</Td>
                                    </Tooltip>
                                }
                                {!item.correct && item.previouslyCorrect &&
                                    <Tooltip p={2} fontWeight={800} bgColor={'#ff9600'} 
                                        label="Mauvaise réponse... Pourtant, tu avais déjà répondu juste à cette question !" 
                                        placement="right" hasArrow>
                                        <Td fontWeight={800} fontSize={'xl'} color={'#ff9600'} isNumeric>+{item.xp}</Td>
                                    </Tooltip>
                                }
                                {!item.correct && !item.previouslyCorrect &&
                                    <Tooltip p={2} fontWeight={800} bgColor={'#ff9600'} 
                                        label="Mauvaise réponse... Mais tu apprends de tes erreurs !"
                                        placement="right" hasArrow>
                                        <Td fontWeight={800} fontSize={'xl'} color={'#ff9600'} isNumeric>+{item.xp}</Td>
                                    </Tooltip>
                                }
                                {earnings.firstTime > 0 &&
                                    <Td fontWeight={900} fontSize={'xl'} color={'#1cb0f6'} isNumeric>+{item.diamonds}</Td>
                                }
                            </Tr>
                        })}
                        {earnings && earnings?.firstTime > 0 && earnings?.diamonds > 0 &&
                            <Tr color={'white'} borderTop={'3pt solid white'}>
                                <Td color={'yellow.200'} fontWeight={800} maxWidth={'400px'} 
                                overflow={'scroll'}
                                // set scrollbar to be invisible
                                css={{
                                    '&::-webkit-scrollbar': {
                                        display: 'none'
                                    }
                                }}>
                                    Première fois que tu termines cette étape !
                                </Td>
                                <Td fontWeight={800} fontSize={'xl'} color={'#ff9600'} isNumeric></Td>
                                <Td fontWeight={900} fontSize={'xl'} color={'#1cb0f6'} isNumeric>{earnings?.firstTime}</Td>
                            </Tr>
                        }
                        {earnings && earnings?.happyhour && earnings?.diamonds > 0 &&
                            <Tr color={'white'} borderTop={'3pt solid white'}>
                                <Td fontSize={'lg'} color={'yellow.200'} fontWeight={900} maxWidth={'400px'} 
                                overflow={'scroll'}
                                // set scrollbar to be invisible
                                css={{
                                    '&::-webkit-scrollbar': {
                                        display: 'none'
                                    }
                                }}>
                                    HAPPY HOURS !
                                </Td>
                                <Td fontWeight={800} fontSize={'xl'} color={'#ff9600'} isNumeric></Td>
                                <Td fontWeight={900} fontSize={'xl'} color={'#1cb0f6'} isNumeric>x2</Td>
                            </Tr>
                        }
                        </Tbody>
                        <Tfoot>
                            <Tr borderTop={'3pt solid white'}>
                                <Th colSpan={1} fontWeight={800} fontSize={'xl'} color={'white'}>TOTAL</Th>
                                <Th isNumeric fontWeight={800} fontSize={'2xl'} color={'#ff9600'}>+{earnings?.xp}</Th>
                                {earnings.firstTime > 0 &&
                                    <Th isNumeric fontWeight={800} fontSize={'2xl'} color={'#1cb0f6'}>+{earnings?.diamonds}</Th>
                                }
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
                }
                <Text mt={2} fontSize={'md'} color={'white'}>Pssst... Passe ton curseur sur les points d'XP pour plus d'infos.</Text>
            </Box>
            </Center>

            <Center>
            <Flex width={'700px'} mt={2}>
                <Spacer />
                <Box
                    onClick={() => {
                        setExitWarning(false);
                        // Play tac sound
                        const audio = new Audio(tac_sound);
                        audio.play();
                        navigate('/');
                    }}
                    cursor={'pointer'}
                    border={'2pt solid #93D333'} 
                    p={3}
                    mt={3}
                    mb={3}
                    className="hvr-grow"
                    bgColor={'#93D333'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
                        OK !
                </Box>
            </Flex>
        </Center>
    </Box>
    }

    {/* MCQ page */}
    {stepState != StepState.FINISH &&
        <Box p="4" color={"white"}>
            <Center mt={4}>
                <Box height={"50px"} onClick={handleButtonClick}>
                    <Flex justifyContent={"flex-end"} pt={3} pe={5} cursor={"pointer"} className="hvr-grow">
                        <Tooltip label="Quitter" p={3} fontWeight={800} mt={2}>
                            <FontAwesomeIcon color="#37464f" size="xl" icon={faArrowLeft} />
                        </Tooltip>
                    </Flex>
                </Box>
                <Box width={'80%'} height={'20px'} bgColor={'#374650'} rounded={'xl'}>
                    <Box width={percent+'%'} bgColor={'#93D333'} height={'100%'} rounded={'xl'}>
                        <Box height={'5px'}></Box>
                        <Box ms={2} me={2} bgColor={'white'} opacity={'0.25'} height={'4px'} rounded={'2xl'} />
                    </Box>
                </Box>
                <Box ms={3}>
                    <Flex>
                        {timerSet && 
                        <Text cursor={'default'} id="timer" fontSize={'lg'} fontWeight={800}>{remainingTime}</Text>
                        }
                        {!timerSet && 
                        <Skeleton height="20px" width="50px" />
                        }
                    </Flex>
                </Box>
            </Center>

            <Center mt={'50px'}>
                <Box alignItems={'start'} width={'700px'}>
                    <Text fontWeight={800} fontSize={'3xl'} cursor={'default'}>Question {currentQuestionIndex+1}/{stepInstance?.questions.length}</Text>
                </Box>
            </Center>

            <Center mt={'40px'}>
                <Box border={'2pt solid #37464F'} p={5} rounded={'xl'} maxWidth={'700px'}>
                    <Text fontWeight={600} fontSize={'xl'} cursor={'default'} dangerouslySetInnerHTML={{__html:stepInstance?.questions[currentQuestionIndex].statement || ''}} />
                </Box>
            </Center>

            {stepInstance?.questions[currentQuestionIndex].code && stepInstance?.questions[currentQuestionIndex].code!.length > 0 &&
                <Center mt={'10px'}>
                    <Box userSelect={'none'} cursor={'default'} border={'2pt solid #37464F'} bgColor={'black'} p={4} rounded={'xl'} width={'700px'}>
                        <pre>{stepInstance?.questions[currentQuestionIndex].code}</pre>
                    </Box>
                </Center>
            }

            {stepInstance?.questions[currentQuestionIndex].answers.map((answer, index) => {
                return <Center mt={index == 0 ? '20px' : '10px'} key={index}>
                    <Box 
                        width={'700px'}
                        onClick={() => select_answer(index)}
                    >
                        {stepState == StepState.DEFAULT &&
                        <Box 
                            className={'hvr-grow'}
                            cursor={selectedIndex == index ? 'default' : 'pointer'}
                            border={selectedIndex == index ? '2pt solid #3F85A7' : '2pt solid #37464F'}
                            p={3} 
                            rounded={'xl'} 
                            width={'100%'} 
                            textAlign={'center'} 
                            bgColor={selectedIndex == index ? '#202F36' : 'rgba(1,1,1,0)'}
                            _hover={{ bg: '#202F36' }}
                        >
                            <Text fontWeight={600} fontSize={'lg'}>{answer.content}</Text>
                        </Box>
                        }
                        {stepState == StepState.SUCCESS &&
                        <Box 
                            cursor={'default'}
                            border={selectedIndex == index ? '2pt solid #79B933' : '2pt solid #37464F'}
                            p={3} 
                            rounded={'xl'} 
                            width={'100%'} 
                            textAlign={'center'} 
                            bgColor={selectedIndex == index ? '#202F36' : 'rgba(1,1,1,0)'}
                        >
                            <Text fontWeight={600} fontSize={'lg'}>{answer.content}</Text>
                        </Box>
                        }
                        {stepState == StepState.FAILURE &&
                        <Box 
                            cursor={'default'}
                            border={selectedIndex == index ? '2pt solid #EF5555' : '2pt solid #37464F'}
                            p={3} 
                            rounded={'xl'} 
                            width={'100%'} 
                            textAlign={'center'} 
                            bgColor={selectedIndex == index ? '#202F36' : 'rgba(1,1,1,0)'}
                        >
                            <Text fontWeight={600} fontSize={'lg'}>{answer.content}</Text>
                        </Box>
                        }
                    </Box>
                </Center>
            })}
        </Box>
    }

    {stepState == StepState.SUCCESS && <Center mt={'10px'}>
        <Box
            width={'700px'}
            rounded={'xl'}
            p={5}
            bgColor={'#202F36'}
            textAlign={'center'}
            cursor={'default'}
        >
            <Text color={'#79B933'} fontSize={'3xl'} fontWeight={800}>Bien joué !</Text>
        </Box>
    </Center>
    }
    {stepState == StepState.FAILURE && <Center mt={'10px'} alignContent={'start'}>
    <Box
        width={'700px'}
        rounded={'xl'}
        p={5}
        bgColor={'#202F36'}
        textAlign={'left'}
        cursor={'default'}
    >
        <Text color={'#EF5555'} fontSize={'3xl'} fontWeight={800} mb={2}>Voici un feedback:</Text>
        <Text color={'#EF5555'} fontSize={'xl'} fontWeight={600}>{feedback}</Text>
    </Box>
</Center>
}

    {/* Next step button */}
    {stepState != StepState.FINISH &&
        <Center mt={'30px'}>
            <Box
                me={4}
                ms={4}
                width={'700px'}
                color={'#131F24'}
            >
                <Flex>
                    <Spacer />
                    <Box
                        onClick={() => stepState == StepState.DEFAULT ? validate_answer() : next_question()}
                        cursor={selectedIndex == -1 ? 'default' : 'pointer'}
                        opacity={selectedIndex == -1 ? '0.5' : '1'}
                        border={stepState != StepState.FAILURE ? '2pt solid #93D333' : '2pt solid #EF5555'} 
                        p={3} 
                        bgColor={stepState != StepState.FAILURE ? '#93D333' : '#EF5555'} width={'fit-content'} rounded={'xl'} fontWeight={800} fontSize={'lg'} color={'#131F24'}>
                            {stepState == StepState.DEFAULT ? 'VALIDER' : 'CONTINUER'}
                        </Box>
                </Flex>
            </Box>
        </Center>
    }

    <Box height={'300px'} width={'100%'} bgColor={'#131F24'}></Box>

  </Box>
}

export default Lesson;